interface ContractBarGraphInterface {
  timestamp: number,
  currentContractCount: number,
  endContractCount: number,
  newContractCount: number,
  aum: number
}

interface ContractSummaryTableInterface {
  [index: string]: any,
  name: string,
  aumAvgPnl: number,
  aumAvgUpnl: number,
  userAvgPnl: number,
  userAvgUpnl: number,
  userMaxPnl: number,
  userMaxUpnl: number,
  userMinPnl: number,
  userMinUpnl: number,
  currency: string
}

interface TableDataInterface {
  [index: string]: string[];
  name: string[]
  userAvg: string[];
  userMax: string[];
  userMin: string[];
  aumAvg: string[];
}

export class ContractBarGraphData implements ContractBarGraphInterface {
  timestamp: number;
  currentContractCount: number;
  endContractCount: number;
  newContractCount: number;
  aum: number;

  constructor(
    timestamp: number = 0,
    currentContractCount: number = 0,
    endContractCount: number = 0,
    newContractCount: number = 0,
    aum: number = 0
  ) {
    this.timestamp = timestamp;
    this.currentContractCount = currentContractCount;
    this.endContractCount = endContractCount;
    this.newContractCount = newContractCount;
    this.aum = aum;
  }

  public static fromGraphql(graphql: ContractBarGraphInterface[]): ContractBarGraphData[] {
    return graphql.map(({ timestamp, currentContractCount, endContractCount, newContractCount, aum }: ContractBarGraphData) => {
      return Object.assign(new ContractBarGraphData(), { timestamp, currentContractCount, endContractCount, newContractCount, aum });
    });
  }
}

export class ContractSummaryTableData implements ContractSummaryTableInterface {
  [index: string]: any;
  name: string;
  aumAvgPnl: number;
  aumAvgUpnl: number;
  userAvgPnl: number;
  userAvgUpnl: number;
  userMaxPnl: number;
  userMaxUpnl: number;
  userMinPnl: number;
  userMinUpnl: number;
  currency: string;

  constructor(
    name: string = '',
    aumAvgPnl: number = 0,
    aumAvgUpnl: number = 0,
    userAvgPnl: number = 0,
    userAvgUpnl: number = 0,
    userMaxPnl: number = 0,
    userMaxUpnl: number = 0,
    userMinPnl: number = 0,
    userMinUpnl: number = 0,
    currency: string = ''
  ) {
    this.name = name;
    this.aumAvgPnl = aumAvgPnl;
    this.aumAvgUpnl = aumAvgUpnl;
    this.userAvgPnl = userAvgPnl;
    this.userAvgUpnl = userAvgUpnl;
    this.userMaxPnl = userMaxPnl;
    this.userMaxUpnl = userMaxUpnl;
    this.userMinPnl = userMinPnl;
    this.userMinUpnl = userMinUpnl;
    this.currency = currency;
  }

  private formatPnl(pnl: number) {
    return pnl < 0 ? `${pnl}%` : `+${pnl}%`;
  }

  get tableData(): TableDataInterface {
    return {
      name: [this.name],
      userAvg: [this.formatPnl(this.userAvgPnl), `(${this.formatPnl(this.userAvgUpnl)})`],
      userMax: [this.formatPnl(this.userMaxPnl), `(${this.formatPnl(this.userMaxUpnl)})`],
      userMin: [this.formatPnl(this.userMinPnl), `(${this.formatPnl(this.userMinUpnl)})`],
      aumAvg: [this.formatPnl(this.aumAvgPnl), `(${this.formatPnl(this.aumAvgUpnl)})`],
    }
  }

  public static fromGraphql(graphql: ContractSummaryTableData[]): ContractSummaryTableData[] {
    return graphql.map(({ name, aumAvgPnl, aumAvgUpnl, userAvgPnl, userAvgUpnl, userMaxPnl, userMaxUpnl, userMinPnl, userMinUpnl, currency }) => {
      return Object.assign(new ContractSummaryTableData(), { name, aumAvgPnl, aumAvgUpnl, userAvgPnl, userAvgUpnl, userMaxPnl, userMaxUpnl, userMinPnl, userMinUpnl, currency });
    });
  }
}

export class ContractBarGraphIntervalData {
  now: number;
  days:  8 | 31 | 365 | 1095;

  constructor(
    now: number = Date.now(),
    days: 8 | 31 | 365 | 1095 = 31
  ) {
    this.now = now;
    this.days = days;
  }

  private static ONE_DAY = 60 * 60 * 24 * 1000;

  get endDate() {
    return this.now - ContractBarGraphIntervalData.ONE_DAY
  }

  get startDate() {
    return this.now - (this.now % ContractBarGraphIntervalData.ONE_DAY) - (ContractBarGraphIntervalData.ONE_DAY * this.days)
  }
  
  get interval() {
    return this.days / 365 < 1 ? 'DAILY' : 'MONTHLY';
  }
}