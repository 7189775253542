export enum TradeHistorySideEnum {
 BUY = "BUY",
 SELL = "SELL",
 ADD = "ADD",
 FEE = "FEE"
}

export enum CurrencyEnum {
  BTC = "BTC",
  USDT = "USDT",
  USD = "USD",
  BNB = "BNB",
  ETH = "ETH",
  TRX = "TRX",
  XRP = "XRP"
}

enum MarketEnum {
  UNKNOWN = "UNKNOWN",
  BINANCE_COIN_M_PERP = "BINANCE_COIN_M_PERP",
  BINANCE_USDT_M_PERP = "BINANCE_USDT_M_PERP",
  BINANCE_SPOT = "BINANCE_SPOT"
}

export interface TradeHistoryInterface {
  [index: string]: any;
  id: string,
  cid: string,
  ticker: string,
  side: TradeHistorySideEnum,
  eventTime: number,
  amount: number,
  currency: CurrencyEnum,
  amountUsdt: number,
  market: MarketEnum
}

export class TradeHistoryData implements TradeHistoryInterface {
  [index: string]: any;
  id: string;
  cid: string;
  ticker: string;
  side: TradeHistorySideEnum;
  eventTime: number;
  amount: number;
  currency: CurrencyEnum;
  amountUsdt: number;
  market: MarketEnum;

  constructor(
    id: string = '',
    cid: string = '',
    ticker: string = '',
    side: TradeHistorySideEnum = TradeHistorySideEnum.BUY,
    eventTime: number = 0,
    amount: number = 0,
    currency: CurrencyEnum = CurrencyEnum.BTC,
    amountUsdt: number = 0,
    market: MarketEnum = MarketEnum.BINANCE_USDT_M_PERP
  ) {
    this.id = id;
    this.cid = cid;
    this.ticker = ticker;
    this.side = side;
    this.eventTime = eventTime;
    this.amount = amount;
    this.currency = currency;
    this.amountUsdt = amountUsdt;
    this.market = market;
  }

  get time(): string {
    if (!this.eventTime) return '-';

    return new Date(this.eventTime).toLocaleString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    });
  }

  get exchange(): string {
    return this.market.split('_')[0];
  }
  
  get formattedMarket(): string {
    switch (this.market) {
      case MarketEnum.UNKNOWN: return 'Unknown';
      case MarketEnum.BINANCE_COIN_M_PERP: return 'COIN-M';
      case MarketEnum.BINANCE_SPOT: return 'SPOT';
      case MarketEnum.BINANCE_USDT_M_PERP: return 'USDⓈ-M';
      default: return '';
    }
  }

  get formattedAmount(): string[] {
    const amountArray = [`${this.amount} ${this.currency}`];
    if (this.currency !== 'USDT' && this.amountUsdt) amountArray.push(`= ${this.amountUsdt} USDT`);
    return amountArray;
  }

  public static fromGraphql(graphql: { node: TradeHistoryInterface }[]): TradeHistoryData[] {
    return graphql.map(el => Object.assign(new TradeHistoryData(), el.node));
  }
}

export interface TradeHistoryFilterInterface {
  symbol?: string,
  side?: string,
  startTime?: number,
  endTime?: number
}

export interface TradeHistoryQueryVariablesInterface extends TradeHistoryFilterInterface {
  contractId: string,
  offset: number,
  first: number,
}

export class TradeHistoryFilterData implements TradeHistoryFilterInterface {
  symbol: string | undefined;
  side: string | undefined;
  startTime: number | undefined;
  endTime: number | undefined;

  constructor(
    symbol: string | undefined = undefined,
    side: string | undefined = undefined,
    startTime: number | undefined = undefined,
    endTime: number | undefined = undefined
  ) {
    this.symbol = symbol;
    this.side = side;
    this.startTime = startTime;
    this.endTime = endTime;
  }
}