import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { StatementTableData } from '@/models/statementGraph';

function StatementTable({ data }: { data: StatementTableData }) {
  
  return (
    <TableContainer style={{ width: 400 }}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell align='left' colSpan={3}>총 누적 매출</TableCell>
            <TableCell align="right">{data.total}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell rowSpan={2} style={{ border: "none" }}></TableCell>
            <TableCell style={{ border: "none" }}></TableCell>
            <TableCell align='right' style={{ border: "none" }}>완납</TableCell>
            <TableCell align="right" style={{ border: "none" }}>{data.paid}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ border: "none" }}></TableCell>
            <TableCell align='right' style={{ border: "none" }}>미납</TableCell>
            <TableCell align="right" style={{ border: "none", width: 1 }}>{data.unpaid}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default StatementTable;