import React from 'react';
import { PieChart, Pie, Legend, Cell, Tooltip } from "recharts";
import { chartDataInterface } from '@/models/userGraph';
import { useTheme } from '@mui/material';

function UserPieChart( { data } : { data: chartDataInterface[] }) {
  const theme = useTheme();

  return (
    <PieChart width={400} height={400}>
      <Legend />
      <Tooltip />
      <Pie
        data={data}
        dataKey="value"
        cx="200"
        cy="170"
        innerRadius={50}
        outerRadius={150}
        labelLine={false}
      >
        <Cell fill={theme.palette.secondary.main}></Cell>
        <Cell fill={theme.palette.primary.light}></Cell>
      </Pie>
    </PieChart>
  )
}

export default UserPieChart;