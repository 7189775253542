import React, { Dispatch, SetStateAction } from 'react';
import { TradeHistoryInterface } from '@/models/tradeHistory';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination } from '@mui/material';

import "react-datepicker/dist/react-datepicker.css";
const PAGE_SIZE = 20;

function TradeHistoryTable(
  {
    data,
    totalCount,
    page,
    setPage
  }: {
    data: TradeHistoryInterface[],
    totalCount: number,
    page: number,
    setPage: Dispatch<SetStateAction<number>>
  } ) {
  const columns = [
    { title: '구분', field: 'side' },
    { title: '매매일시 (UTC)', field: 'time' },
    { title: '가상자산', field: 'ticker' },
    { title: '거래소', field: 'exchange' },
    { title: '시장', field: 'formattedMarket' },
    { title: '금액', field: 'formattedAmount' }
  ]

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            {columns.map(column => {
              return <TableCell key={column.title} align="center">{column.title}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data.map((row, idx) => {
              return (
                <TableRow key={row.id} hover>
                  <TableCell>{idx + 1}</TableCell>
                  {
                    columns.map(column => {
                      if (column.title === '금액') return <TableCell key={column.title} align="right">
                        {
                          row[column.field].map((el: string, idx: number) => {
                            return <div style={{ fontSize: "0.875rem" }} key={idx}>{el}</div>
                          })
                        }
                      </TableCell>
                      else return <TableCell key={column.title} align="center">{row[column.field]}</TableCell>
                    })
                  }
                </TableRow>
              )
            })
          }
          <TableRow>
            <TableCell colSpan={5} align="center">
              {
                data.length
                ? <Pagination
                  count={Math.ceil(totalCount / PAGE_SIZE)}
                  onChange={(e, page) => setPage(page)}
                  style={{ width: '100%' }}
                  page={page}
                />
                : "No records to display"
              }
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TradeHistoryTable;