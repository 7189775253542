import React, { useState, useEffect } from 'react';
import { Stack, Button, Divider, Dialog, DialogActions, DialogTitle, DialogContent, CircularProgress, Backdrop } from '@mui/material';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useHistory } from 'react-router';
import { MAIN_GRAPHS } from '@/graphql/serverQueries';
import { useQuery, useMutation } from '@apollo/client';
import { UserPieChartData } from '@/models/userGraph';
import { StatementTableData } from '@/models/statementGraph';
import UserBarGraph from '@/containers/charts/UserBarGraph';
import StatementBarGraph from '@/containers/charts/StatementBarGraph';
import UserPieChart from '@/containers/charts/UserPieChart';
import StatementTable from '@/containers/tables/StatementTable';
import { ContractSummaryTableData } from '@/models/contractGraph';
import ContractBarGraph from '@/containers/charts/ContractBarGraph';
import ContractSummaryTable from '@/containers/tables/ContractSummaryTable';
import { SEND_NOTIFICATION } from '@/graphql/serverMutations';

enum EnvEnum {
  STAGING = 'STAGING',
  PROD = 'PROD'
}

function Main() {
  const history = useHistory();

  // 공지사항 모달 관련 상태
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const [sendNotification, sendNotificationResult] = useMutation(SEND_NOTIFICATION);

  const handleSendNotification = (env: EnvEnum) => () => {
    if (env === EnvEnum.STAGING) {
      setLoading(true)
      sendNotification({ variables: { env }});
    }
    else if (env === EnvEnum.PROD) {
      setOpenConfirm(true);
    }
  }

  const handleConfirmNotification = (confirm: boolean) => () => {
    if (confirm) {
      setLoading(true)
      sendNotification({ variables: { env: EnvEnum.PROD }});
    }
    else {
      setOpenDialog(false);
      setOpenConfirm(false);
    }
  }

  // 회원정보 관련 상태
  const [userPieChart, setUserPieChart] = useState(
    new UserPieChartData().chartData
  );
  const [totalUserCount, setTotalUserCount] = useState(0);

  // 매출정보 관련 상태
  const [statementTable, setStatementTable] = useState(new StatementTableData());

  // 계약정보 관련 상태
  const [contractSummaryTable, setContractSummaryTable] = useState([
    new ContractSummaryTableData()
  ])

  useQuery(MAIN_GRAPHS, {
    onCompleted(data) {
      const userPieChart = UserPieChartData.fromGraphql(data.userPieChart);
      setUserPieChart(userPieChart.chartData);
      setTotalUserCount(userPieChart.totalUserCount);
      setStatementTable(StatementTableData.fromGraphql(data.statementSummary));
      setContractSummaryTable(ContractSummaryTableData.fromGraphql(data.contractInfo));
    }
  });

  useEffect(() => {
    if (sendNotificationResult.data?.sendNotification) {
      if (sendNotificationResult.data.sendNotification.errors !== null) {
        alert(sendNotificationResult.data.sendNotification.errors[0].message);
      }
      else if (sendNotificationResult.data.sendNotification.errors === null) {
        alert(`${sendNotificationResult.data.sendNotification.count}개의 공지사항을 적용하였습니다.`)
      }
    }
    
    setLoading(false);
    setOpenDialog(false);
    setOpenConfirm(false);
  }, [sendNotificationResult.data?.sendNotification])

  return (
    <div className="page-content main-page">
      <Stack spacing={5} style={{ maxWidth: 1440 }}>
        <Stack direction="row" spacing={3}>
          <Button
            onClick={() => window.open('https://grafana.tool.rabit.bot/?orgId=2')}
            endIcon={<ChevronRightIcon/>}
            variant="outlined"
            color="primary"
            className="main-menu-button"
          >운용모니터링</Button>
          <Button
            onClick={() => history.push('/users')}
            endIcon={<ChevronRightIcon/>}
            variant="outlined"
            className="main-menu-button"
          >회원정보</Button>
          <Button
            onClick={() => window.open('https://quantit.zendesk.com/hc/ko')}
            endIcon={<ChevronRightIcon/>}
            variant="outlined"
            className="main-menu-button"
          >젠데스크</Button>
          <Button
            onClick={() => window.open('https://analytics.google.com/analytics/web/?authuser=0#/p275939742/reports/dashboard?r=firebase-overview')}
            endIcon={<ChevronRightIcon/>}
            variant="outlined"
            className="main-menu-button"
          >마케팅</Button>
          <Button
            onClick={() => setOpenDialog(true)}
            endIcon={<ChevronRightIcon/>}
            variant="outlined"
            className="main-menu-button"
          >공지사항 적용</Button>
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <DialogTitle>공지사항 적용</DialogTitle>
            <DialogContent>
              신규 공지사항을 적용할 환경을 선택해 주세요 (STAGING or PROD)
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'center'}}>
              {
                Object.keys(EnvEnum).map(el => {
                  return (
                    <Button
                      onClick={handleSendNotification(el as EnvEnum)}
                      variant="contained"
                      style={{ width: 90 }}
                      key={el}
                    >
                      {el}
                    </Button>
                  )
                })
              }
            </DialogActions>
          </Dialog>
          <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)}>
            <DialogContent>
              정말로 prod 환경에 공지사항을 적용하시겠습니까?
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'center'}}>
              <Button
                onClick={handleConfirmNotification(true)}
                variant="contained"
                style={{ width: 90 }}
              >
                예
              </Button>
              <Button
                onClick={handleConfirmNotification(false)}
                variant="contained"
                style={{ width: 90 }}
              >
                아니오
              </Button>
            </DialogActions>
          </Dialog>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 101 }}
            open={loading}
            style={{ margin: 0 }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Stack>
        <Stack spacing={3} style={{ width: "100%" }}>
          <h2>회원정보 | 명</h2>
          <Divider style={{ marginBottom: 50 }}/>
          <Stack direction="row" spacing={3} style={{ justifyContent: "space-between", paddingBottom: 50 }}>
            <UserBarGraph />
            <Stack>
              <h5>{`전체 ${totalUserCount}명`}</h5>
              <UserPieChart data={userPieChart}/>
            </Stack>
          </Stack>
        </Stack>
        <Stack spacing={3} style={{ width: "100%", paddingBottom: 50 }}>
          <h2>매출정보 | USD</h2>
          <Divider style={{ marginBottom: 50 }}/>
          <Stack direction="row" spacing={3} style={{ justifyContent: "space-between" }}>
            <StatementBarGraph />
            <div>
              <StatementTable data={statementTable} />
            </div>
          </Stack>
        </Stack>
        <Stack spacing={3} style={{ width: "100%", paddingBottom: 50 }}>
          <h2>계약정보</h2>
          <Divider style={{ marginBottom: 50 }}/>
          <Stack spacing={3}>
            <ContractBarGraph />
            <Stack style={{ alignItems: "center" }}>
              <ContractSummaryTable data={contractSummaryTable} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
}

export default Main;