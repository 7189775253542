import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ContractSummaryTableData } from '@/models/contractGraph';

function ContractSummaryTable({ data }: { data: ContractSummaryTableData[] }) {

  const formatDate = (date: Date) => {
    return date.toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  }

  const columns = [
    { title: '', field: 'name' },
    { title: '회원 평균', field: 'userAvg' },
    { title: '회원 최고', field: 'userMax' },
    { title: '회원 최저', field: 'userMin' },
    { title: 'AUM 평균', field: 'aumAvg' }
  ]

  return (
    <TableContainer style={{ width: 1000 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={data.length + 1} align="right" style={{ border: "none", paddingBottom: 0 }}>
              <div>{`실현수익률 | (미실현수익률) | ${formatDate(new Date())}`}</div>
            </TableCell>
          </TableRow>
          <TableRow>
            {columns.map(column => {
              return <TableCell key={column.title} align="center">{column.title}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data.map(row => {
              return (
                <TableRow key={row.name}>
                  {
                    columns.map(column => {
                      return <TableCell key={column.title} align="center">
                        {
                          row.tableData[column.field].map(el => <div>{el}</div>)
                        }
                      </TableCell>
                    })
                  }
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ContractSummaryTable;