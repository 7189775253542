import { gql } from "@apollo/client";

export const SIGN_IN = gql`
  mutation SIGN_IN ($username: String! $password: String!) {
    signin(username: $username, password: $password) {
      token
      refreshExpiresIn
    }
  }
`

export const SIGNUP = gql`
  mutation SIGNUP (
    $code: String!
    $email: String!
    $name: String!
    $password: String!
    $phone: String
  ) {
    signup(
      code: $code,
      email: $email,
      name: $name,
      password: $password,
      phone: $phone
    ) {
      ok
      errors {
        key
        message
      }
    }
  }
`

export const REFRESH_TOKEN = gql`
  mutation REFRESH_TOKEN ($token: String) {
    refreshToken(token: $token) {
      token
      refreshExpiresIn
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation RESET_PASSWORD ($password: String!) {
    resetPassword(password: $password) {
      ok
      errors {
        key
        message
      }
    }
  }
`

export const SEND_NOTIFICATION = gql`
  mutation SEND_NOTIFICATION ($env: EnvEnum!) {
    sendNotification(env: $env) {
      count
      errors {
        key
        message
      }
    }
  }
`