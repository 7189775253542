import React from 'react';
import { CircularProgress } from '@mui/material';

function Landing() {
  return (
    <div className="page-content landing-page">
      <CircularProgress color="secondary" size={100} />
    </div>
  );
}

export default Landing;
