import { gql } from "@apollo/client";

export const MY_NAME = gql`
  query ME {
    me {
      email
      name
    }
  }
`

export const ME = gql`
  query ME {
    me {
      username
      email
      name
      phone
    }
  }
`

export const USERS = gql`
  query Users($kwd: String, $offset: Int, $first: Int, $userInput: UserInput) {
    users(kwd: $kwd, offset: $offset, first: $first, userInput: $userInput) {
      edges {
        node {
          id
          email
          phone
          country
          joinDate
          userType
          latestContractDate
          cumulativeContractCount
          cumulativeStatementAmount
        }
      }
      totalCount
    }
  }
`

export const USER = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      email
      phone
      provider
      joinDate
      country
      userType
      lastOpenDate
    }
    detailedStatements(userId: $id) {
      edges {
        node {
          amount
          status
          billingTime
          cardInfo {
            last4
          }
          performanceFee
          c2model {      
            strategyName
            currency
          }
        }
      }
    }
  }
`

export const CONTRACTS = gql`
  query Contracts($userId: ID!, $states: [ContractStateEnum], $c2modelId: ID, $offset: Int, $first: Int) {
    contracts(userId: $userId, states: $states, c2modelId: $c2modelId, offset: $offset, first: $first) {
      edges {
        node {
          id
          cid
          state
          c2modelName
          c2modelSymbols
          startDate
          endDate
          rate
        }
      }
      totalCount
    }
  }
`

export const TRADE_HISTORY = gql`
  query TradeHistory($contractId: ID!, $symbol: String, $side: TradeHistorySideEnum, $startTime: Float, $endTime: Float, $offset: Int, $first: Int) {
    tradeHistory(contractId: $contractId, symbol: $symbol, side: $side, startTime: $startTime, endTime: $endTime, offset: $offset, first: $first) {
      edges {
        node {
          id,
          cid
          ticker
          side
          eventTime
          amount
          currency
          amountUsdt
          market
        }
      }
      totalCount
    }
  }
`

export const MAIN_GRAPHS = gql`
  query MainGraphs {
    userPieChart {
      paidUserCount
      freeUserCount
      totalUserCount
    }
    statementSummary {
      total
      paid
      unpaid
    }
    contractInfo {
      name
      activeCount
      endCount
      aumAvgPnl
      aumAvgUpnl
      userAvgPnl
      userAvgUpnl
      userMaxPnl
      userMaxUpnl
      userMinPnl
      userMinUpnl
      currency
    }
  }
`

export const USER_BAR_GRAPH = gql`
  query UserBarGraph($startDate: Float, $endDate: Float, $interval: IntervalEnum) {
    userBarGraph(startDate: $startDate, endDate: $endDate, interval: $interval) {
      timestamp
      todayUserCount
      cumulativeUserCount
      leaveUserCount
    }
  }
`

export const CONTRACT_BAR_GRAPH = gql`
  query ContractBarGraph($startDate: Float!, $endDate: Float!, $interval: IntervalEnum, $c2modelId: ID) {
    contractBarGraph(startDate: $startDate, endDate: $endDate, interval: $interval, c2modelId: $c2modelId) {
      items {
        timestamp
        newContractCount
        currentContractCount
        endContractCount
        aum
      }
    }
  }
`

export const STATEMENT_BAR_GRAPH = gql`
  query Statements($startDate: Float, $endDate: Float, $interval: IntervalEnum) {
    statements(startDate: $startDate, endDate: $endDate, interval: $interval) {
      timestamp
      amount
    }
  }
`

export const USERS_FILTERS = gql`
  query UsersFilters {
    c2models {
      edges {
        node {
          id
          isRunning
          strategyName
          currency
        }
      }
    }
    country
  }
`

export const C2MODEL_FILTER = gql`
query C2ModelFilters($isRunning: Boolean) {
  c2models(isRunning: $isRunning) {
    edges {
      node {
        id
        isRunning
        strategyName
      }
    }
  }
}
`

export const USERS_COUNT = gql`
query usersCount ($kwd: String, $userInput: UserInput) {
  usersCount(kwd: $kwd, userInput: $userInput)
}
`