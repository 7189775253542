import { C2modelData } from "./contracts";

enum StatementStatusEnum {
  NONPAYMENT,
  PAID,
  SKIP
}

class StatementStatusClass {
  status: StatementStatusEnum;

  constructor(status: StatementStatusEnum = StatementStatusEnum.PAID) {
    this.status = status;
  }

  get label() {
    switch (this.status) {
      case StatementStatusEnum.NONPAYMENT: return 'Failed';
      case StatementStatusEnum.PAID: return 'Success';
      case StatementStatusEnum.SKIP: return 'Skip';
      default: return '';
    }
  }
}

interface DetailedStatementsInterface {
  [index: string]: any,
  amount: number,
  status: StatementStatusEnum,
  billingTime: number,
  cardInfo: { last4: string } | null,
  performanceFee: number,
  c2model: C2modelData
}

export class DetailedStatementsData {
  [index: string]: any;
  amount: number;
  status: StatementStatusClass;
  billingTime: number;
  cardInfo: { last4: string } | null;
  performanceFee: number;
  c2model: C2modelData; 

  constructor(
    amount: number = 0,
    status: StatementStatusClass = new StatementStatusClass(),
    billingTime: number = 0,
    cardInfo: { last4: string } | null = null,
    performanceFee: number = 0,
    c2model: C2modelData = new C2modelData()
  ) {
    this.amount = amount;
    this.status = status;
    this.billingTime = billingTime;
    this.cardInfo = cardInfo;
    this.performanceFee = performanceFee;
    this.c2model = c2model;
  }

  get time(): string {
    if (!this.billingTime) return '-';

    return new Date(this.billingTime).toLocaleString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour12: false
    });
  }

  get botName() {
    return this.c2model.strategyName;
  }

  get formattedAmount() {
    switch (this.status.label) {
      case 'Skip': return '-';
      default: return `$${this.amount}`;
    }
  }

  get formattedFee() {
    switch (this.status.label) {
      case 'Skip': return '-';
      default: return `${this.performanceFee} ${this.c2model.currency}`;
    }
  }

  get maskedCardInfo() {
    return this.cardInfo ? `**** **** **** ${this.cardInfo.last4}` : '-'
  }

  get billingStatus() {
    return this.status.label;
  }

  public static fromGraphql(graphql: { node: DetailedStatementsInterface }[]): DetailedStatementsData[] {
    return graphql.map(({ node }) => {
      const status = new StatementStatusClass(Object.values(StatementStatusEnum).indexOf(node.status));
      return Object.assign(new DetailedStatementsData(), node, { status })
    })
  }
}