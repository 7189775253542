import React, { useState } from 'react';
import { useQuery,  useMutation, useApolloClient } from "@apollo/client";
import { RESET_PASSWORD } from "@/graphql/serverMutations";
import { ME } from '@/graphql/serverQueries';
import { MeInterface } from "@/models/agent";
import { Paper, Stack, TextField, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { currentSigninInfo } from '@/cache';

function Profile() {
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    password: '',
    checkPassword: ''
  })

  const { password, checkPassword } = values;

  const [me, setMe] = useState<MeInterface>({
    email: '',
    name: '',
    phone: ''
  })

  const { email, name, phone } = me;
  
  useQuery(ME, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      if (data && data.me) setMe(data.me);
      else {
        alert("인증이 만료되었습니다. 다시 로그인해 주세요.");
        localStorage.clear();
        client.clearStore();
      }
    }
  });

  const handleChange = (prop: 'password' | 'checkPassword') => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [handleResetPassword, ] = useMutation(RESET_PASSWORD, {
    onCompleted({ resetPassword: { ok } }) {
      setLoading(false);
      if (ok) {
        alert('새로운 비밀번호로 다시 로그인해 주세요.');
      } else {
        alert('잘못된 요청입니다. 다시 로그인해 주세요.');
      }
      currentSigninInfo(null);
      localStorage.clear();
    }
  })

  const handleSubmit = () => {
    if (password.length < 8) {
      alert('8자 이상의 비밀번호를 입력해주세요.');
      return;
    }
    if (password !== checkPassword) {
      alert('비밀번호가 일치하지 않습니다.');
      return;
    }
    
    setLoading(true);
    const variables = { password };
    handleResetPassword({ variables });
  }

  const formatPhone = (phone: string) => {
    return `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6)}`;
  }

  return (
    <div className="page-content profile-page">
      <Paper id="profile-box" elevation={3}>
        <h1 style={{ fontSize: 36, marginBottom: 20 }}>My Account</h1>
        <Stack style={{ width: '100%' }}>
          <TextField
            fullWidth
            label="이메일"
            value={email}
            inputProps={{ readOnly: true }}
            style={{ marginBottom: 20 }}
            autoComplete='username email'
          />
          <TextField
            fullWidth
            label="이름"
            value={name}
            inputProps={{ readOnly: true }}
            style={{ marginBottom: 20 }}
            autoComplete='name'
          />
          <TextField
            fullWidth
            label="전화번호"
            value={formatPhone(phone)}
            inputProps={{ readOnly: true }}
            style={{ marginBottom: 20 }}
            autoComplete='tel'
          />
          <TextField
            fullWidth
            label="비밀번호"
            value={password}
            style={{ marginBottom: 20 }}
            onChange={handleChange('password')}
            type='password'
            autoComplete='new-password'
          />
          <TextField
            fullWidth
            label="비밀번호 확인"
            value={checkPassword}
            style={{ marginBottom: 20 }}
            onChange={handleChange('checkPassword')}
            type='password'
          />
        </Stack>
        <LoadingButton
          onClick={handleSubmit}
          loading={loading}
          loadingPosition="center"
          loadingIndicator={<CircularProgress color="inherit" size={16} style={{color: "#4A46B8"}} />}
          variant="contained"
          style={{ width: "100%", backgroundColor: loading ? '#B7B5DE' : '#4A46B8', height: "50px", color: '#ffffff' }}
        >
          Change Password
        </LoadingButton>
      </Paper>
    </div>
  );
}

export default Profile;