export enum DateRangeEnum {
  ONE_WEEK = "1W",
  ONE_MONTH = "1M",
  ONE_YEAR = "1Y",
  THREE_YEARS = "3Y"
}

interface UserBarGraphInterface {
  timestamp: number,
  todayUserCount: number,
  cumulativeUserCount: number,
  leaveUserCount: number
}

interface UserPieChartInterface {
  totalUserCount: number,
  paidUserCount: number,
  freeUserCount: number
}

export interface chartDataInterface {
  name: string,
  value: number
}

export class UserBarGraphData implements UserBarGraphInterface {
  timestamp: number;
  todayUserCount: number;
  cumulativeUserCount: number;
  leaveUserCount: number;

  constructor(
    timestamp: number = 0,
    todayUserCount: number = 0,
    cumulativeUserCount: number = 0,
    leaveUserCount: number = 0
  ) {
    this.timestamp = timestamp;
    this.todayUserCount = todayUserCount;
    this.cumulativeUserCount = cumulativeUserCount;
    this.leaveUserCount = leaveUserCount;
  }

  public static fromGraphql(graphql: UserBarGraphInterface[]): UserBarGraphData[] {
    return graphql.map(el => Object.assign(new UserBarGraphData(), el));
  }
}

export class UserPieChartData implements UserPieChartInterface {
  totalUserCount: number;
  paidUserCount: number;
  freeUserCount: number;

  constructor(
    totalUserCount: number = 0,
    paidUserCount: number = 0,
    freeUserCount: number = 0
  ) {
    this.totalUserCount = totalUserCount;
    this.paidUserCount = paidUserCount;
    this.freeUserCount = freeUserCount;
  }

  get chartData(): chartDataInterface[] {
    return [
      { name: '유료회원', value: this.paidUserCount },
      { name: '무료회원', value: this.freeUserCount }
    ]
  }

  public static fromGraphql(graphql: UserPieChartInterface): UserPieChartData {
    return Object.assign(new UserPieChartData(), graphql);
  }
}