import { InMemoryCache } from "@apollo/client";
import { SigninInfo } from "@/models/signinInfo";
import { makeVar } from "@apollo/client";

export const currentSigninInfo = makeVar<SigninInfo | null>(null);

const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedin() {
          return currentSigninInfo()
        }
      },
    },
  },
});

export default cache;
