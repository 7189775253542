import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Select, TextField, SelectChangeEvent, FormControl, Stack, Modal } from '@mui/material';
import { USER, CONTRACTS, TRADE_HISTORY, C2MODEL_FILTER } from '@/graphql/serverQueries';
import { C2modelData, ContractsData } from '@/models/contracts';
import { TradeHistoryData, TradeHistoryQueryVariablesInterface, TradeHistoryFilterData } from '@/models/tradeHistory';
import ContractsTable from '@/containers/tables/ContractsTable';
import TradeHistoryTable from '@/containers/tables/TradeHistoryTable';
import TradeHistoryFilters from '@/containers/filters/TradeHistoryFilters';
import { UserInfoData, UserTypeClass } from '@/models/userInfo';
import DetailedStatementsTable from '@/containers/tables/DetailedStatementsTable';
import { DetailedStatementsData } from '@/models/detailedStatements';
const PAGE_SIZE_CONTRACTS = 5;
const PAGE_SIZE_TRADEHISTORY = 20;

function User() {
  const location = useLocation();
  const id = location.pathname.split('/')[2];

  // 1. 회원정보 관련 상태
  const [userState, setUserState] = useState(new UserInfoData());

  // 2. 투자현황 관련 상태
  const [contractsState, setContractsState] = useState([
    new ContractsData()
  ]);

  const [c2ModelState, setC2ModelState] = useState('ACTIVE');

  const [contractState, setContractState] = useState(new ContractsData());

  const [contractsTablePage, setContractsTablePage] = useState(1);

  const [totalContractsCount, setTotalContractsCount] = useState(PAGE_SIZE_CONTRACTS);

  const [c2ModelFilter, setC2ModelFilter] = useState([new C2modelData()]);

  // 3. 거래내역 관련 상태
  const [openModal, setOpenModal] = useState(false);

  const [tradeHistoryState, setTradeHistoryState] = useState([
    new TradeHistoryData()
  ]);

  const [tradeHistoryTablePage, setTradeHistoryTablePage] = useState(1);

  const [totalTradeHistoryCount, setTotalTradeHistoryCount] = useState(PAGE_SIZE_TRADEHISTORY);

  const [tradeHistoryFilterState, setTradeHistoryFilterState] = useState(new TradeHistoryFilterData());

  // 4. 결제정보 관련 상태
  const [detailedStatementsState, setDetailedStatementsState] = useState([new DetailedStatementsData()])

  // 1. 회원정보 관련 쿼리
  useQuery(USER, {
    variables: { id },
    onCompleted({ user, detailedStatements }) {
      if (user) {
        const { id, email, phone, provider, joinDate, country, lastOpenDate } = user;
        const userType = new UserTypeClass(user.userType);
        setUserState(new UserInfoData(id, email, phone, provider, joinDate, country, userType, lastOpenDate));
        setDetailedStatementsState(DetailedStatementsData.fromGraphql(detailedStatements.edges));
      }
    }
  })

  // 2. 투자현황 관련 쿼리
  useQuery(C2MODEL_FILTER, {
    fetchPolicy: "cache-first",
    onCompleted({ c2models }) {
      setC2ModelFilter(C2modelData.fromGraphql(c2models.edges));
    }
  });

  const [getContracts, ] = useLazyQuery(CONTRACTS, {
    fetchPolicy: 'network-only',
    onCompleted({ contracts }) {
      setContractsState(ContractsData.fromGraphql(contracts.edges));
      setTotalContractsCount(contracts.totalCount);
    }
  })

  // 3. 거래내역 관련 쿼리
  const [getTradeHistory, ] = useLazyQuery(TRADE_HISTORY, {
    fetchPolicy: 'network-only',
    onCompleted({ tradeHistory }) {
      const { edges, totalCount } = tradeHistory;
      const data = TradeHistoryData.fromGraphql(edges);
      setTradeHistoryState(data);
      setTotalTradeHistoryCount(totalCount);
      setOpenModal(true);
    }
  })

  const handleSelectC2model = (e: SelectChangeEvent<string>) => setC2ModelState(e.target.value);

  // useEffect
  useEffect(() => {
    const variables: {
      userId: string,
      offset: number,
      first: number,
      states?: Array<string>,
      c2modelId?: string
    } = { userId: id, offset: ((contractsTablePage - 1) * PAGE_SIZE_CONTRACTS), first: PAGE_SIZE_CONTRACTS };

    if (c2ModelState === 'ACTIVE') {
      variables.states = [c2ModelState];
    } else {
      variables.c2modelId = c2ModelState;
    }

    getContracts({ variables });
  }, [c2ModelState, getContracts, id, contractsTablePage]);

  useEffect(() => {
    setTradeHistoryState([
      new TradeHistoryData()
    ]);
    setContractState(new ContractsData());
    setContractsTablePage(1);
  }, [c2ModelState, id]);

  useEffect(() => {
    setTradeHistoryTablePage(1);
    setTradeHistoryFilterState(new TradeHistoryFilterData());
  }, [contractState]);

  useEffect(() => {
    if (contractState.id.length) {
      const variables: TradeHistoryQueryVariablesInterface = {
        contractId: contractState.id,
        offset: ((tradeHistoryTablePage - 1) * PAGE_SIZE_TRADEHISTORY),
        first: PAGE_SIZE_TRADEHISTORY,
        ...tradeHistoryFilterState
      };
  
      getTradeHistory({ variables });
    }
  }, [contractState.id, getTradeHistory, tradeHistoryTablePage, tradeHistoryFilterState]);

  return (
    <div className="page-content user-page">
      <Stack spacing={7}>
        <Stack spacing={2}>
          <h2>기본 정보</h2>
          <Stack direction="row" spacing={1}>
            {
              userState.displayInfo.slice(0,5).map(el => {
                return (
                  <FormControl key={el.label}>
                    <TextField
                      label={el.label}
                      value={el.value}
                      inputProps={{ readOnly: true }}
                      variant="outlined"
                    />
                  </FormControl>
                )
              })
            }
          </Stack>
          <Stack>
            <FormControl>
              <TextField
                label={userState.displayInfo[5].label}
                value={userState.displayInfo[5].value}
                inputProps={{ readOnly: true }}
                variant="outlined"
              />
            </FormControl>
          </Stack>
        </Stack>
        <Stack spacing={2}>
          <Stack direction="row" style={{ justifyContent: "space-between", alignItems: "center"}}>
            <h2>계약 정보</h2>
            <FormControl>
              <Select
                native
                labelId="label"
                onChange={handleSelectC2model}
                defaultValue={c2ModelState}
                variant="outlined"
              >
                <option value="ACTIVE">ACTIVE</option>
                {
                  c2ModelFilter.map((el: C2modelData) => {
                    return <option value={el.id} key={el.id}>{el.label}</option>
                  })
                }
              </Select>
            </FormControl>
          </Stack>
          <ContractsTable
            data={contractsState}
            totalCount={totalContractsCount}
            page={contractsTablePage}
            setPage={setContractsTablePage}
            setContractState={setContractState}
          />
        </Stack>
        <Stack spacing={2}>
          <Stack direction="row" style={{ justifyContent: "space-between", alignItems: "center"}}>
            <h2>결제 정보</h2>
          </Stack>
          <DetailedStatementsTable
            data={detailedStatementsState}
          />
        </Stack>
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          style={{ overflow: 'scroll' }}
        >
          <Stack spacing={2} sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -25%)',
            width: '80%',
            bgcolor: 'white',
            p: 4,
          }}>
            <h2>{`거래내역: ${contractState.cid}`}</h2>
            <TradeHistoryFilters
              cid={contractState.cid}
              c2modelSymbols={contractState.c2modelSymbols}
              filters={tradeHistoryFilterState}
              setFilters={setTradeHistoryFilterState}
              setPage={setTradeHistoryTablePage}
            />
            <TradeHistoryTable
              data={tradeHistoryState}
              totalCount={totalTradeHistoryCount}
              page={tradeHistoryTablePage}
              setPage={setTradeHistoryTablePage}
            />
          </Stack>
        </Modal>
      </Stack>
    </div>
  );
}

export default User;