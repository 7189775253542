import React, { Fragment }  from 'react';

// react routing
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import routes from "@/config/route";



// GraphQL
import {
  ApolloProvider,
  ApolloClient,
  NormalizedCacheObject,
  createHttpLink,
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';

import cache from "@/cache"

// style import
import '@style/index.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function App() {

  // Graphql Setting
  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_END_POINT}/graphql`,
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: `jwt ${token}` || '',
      }
    }
  });

  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache: cache,
    link: authLink.concat(httpLink),
  });

  const history = createBrowserHistory();

  const theme = createTheme({
    palette: {
      primary: {
        light: '#7f72eb',
        main: '#4a46b8',
        dark: '#001e87',
        contrastText: '#fff',
      },
      secondary: {
        light: '#67ffe7',
        main: '#0dddb5',
        dark: '#00aa85',
        contrastText: '#000',
      },
      error: {
        main: '#F11320'
      }
    },
  });

  return (
    // 라우터와 병렬적으로 컴포넌트 추가 가능한 구조

    <ThemeProvider theme={theme}>
    <ApolloProvider client={client}>
        <Fragment> 
          <Router history={history}>
            <Switch>
              {routes.map((r) => (
                <Route key={r.path} path={r.path} component={r.component} exact />
              ))}
              <Redirect path="*" to="/" />
            </Switch>
          </Router>
        </Fragment>
    </ApolloProvider>

    </ThemeProvider>
  );
}