import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useLazyQuery,  useMutation, useApolloClient } from "@apollo/client";
import { SIGN_IN, SIGNUP, RESET_PASSWORD } from "@/graphql/serverMutations";
import { ME } from '@/graphql/serverQueries';
import { AgentData, MeInterface } from "@/models/agent";

function Agent({ agentData }: { agentData: AgentData }) {
  const history = useHistory();
  const client = useApolloClient();

  const { page, items, title, submit, toggle } = agentData;

  const [inputs, setInputs] = useState({
    email: '',
    password: '',
    checkPassword: '',
    code: '',
    name: '',
    phone: ''
  });

  const { email, password, checkPassword, code, name, phone } = inputs;

  const [errorMessages, setErrorMessages] = useState({
    errorPW: '',
    errorCheckPW: '',
  });

  const { errorPW, errorCheckPW } = errorMessages;

  const [me, setMe] = useState<MeInterface>({
    email: '',
    name: '',
    phone: ''
  })
  
  const [getMe, ] = useLazyQuery(ME, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      if (data && data.me) setMe(data.me);
      else {
        alert('permission denied. log in again');
        localStorage.clear();
        client.clearStore();
      }
    }
  });
  
  const handleErrorMessage = (type: string, message: string) => {
    setErrorMessages({
      ...errorMessages,
      [type]: message
    })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;
    setInputs({
      ...inputs,
      [name]: value
    });

    if (name === 'password') {
      if (value.length < 8 && value.length) {
        handleErrorMessage('errorPW', 'password too short');
      } else {
        handleErrorMessage('errorPW', '');
      }
    }
    if (name === 'checkPassword') {
      if (password !== value) {
        handleErrorMessage('errorCheckPW', 'password does not match');
      } else {
        handleErrorMessage('errorCheckPW', '');
      }
    }
  }

  const toggleSection = () => {
    if (page === 'signup') history.push("/login");
    if (page === 'signin') history.push("/register");
  }

  const [handleSignin, ] = useMutation(SIGN_IN, {
    onCompleted({ signin }) {
      if (signin) {
        localStorage.setItem('jwt', `jwt ${signin.token}`);
        history.replace("/");
      } else {
        alert('please check email or password')
      }
    }
  });

  const [handleSignup, ] = useMutation(SIGNUP, {
    onCompleted({ signup: { ok, errors } }) {
      if (ok) {
        history.push("/login");
      } else {
        const { key } = errors[0];
        switch (key) {
          case 'INVALID_CODE':
            alert('invalid code')
            return;
          case 'INVALID_STATE':
            alert('duplicate account')
            return;
          default:
            alert('please try again')
            return;
        }
      }
    }
  })

  const [handleResetPassword, ] = useMutation(RESET_PASSWORD, {
    onCompleted({ resetPassword: { ok } }) {
      if (ok) {
        alert('please log in again with your new password')
      } else {
        alert('permission denied. log in again')
      }
      localStorage.clear();
      client.clearStore();
    }
  })

  const handleSubmit = () => {
    // if (errorPW.length) {
    //   alert(errorPW);
    //   return;
    // }
    if (errorCheckPW.length) {
      alert(errorCheckPW);
      return;
    }

    if (page === 'signin') {
      if (!email.length 
        || !password.length) {
        alert('please enter');
        return;
      }

      const variables = {
        username: email,
        password
      };

      handleSignin({ variables });
    }
    if (page === 'signup') {
      if (!email.length
        || !password.length
        || !code.length
        || !checkPassword.length
        || !name.length
        || !phone.length ) {
        alert('please enter');
        return;
      }
      // if (!Object.values(errorMessages).every(el => el === '')
      //   || /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[a-zA-Z]+\.[a-zA-Z]+$/i.exec(email)) {
      //   alert('email error')
      //   return;
      // }
      // password error 떠 있으면 proceed 못하게!!!!!
      const alertString = `Please check your information:\n\nemail: ${email}\nname: ${name}\nphone: ${phone}\n\nProceed to signup?`
      if (window.confirm(alertString)) {
        const variables = {
          code,
          email,
          name,
          password,
          phone: phone.replace(/[^\d]/g, "")
        };
        handleSignup({ variables })
      }
    }
    if (page === 'profile') {
      if (!password.length) {
        alert('please enter');
        return;
      }
      const variables = { password };
      handleResetPassword({ variables });
    }
  }

  const formatPhone = (value: string) => {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, "");
    const length = phoneNumber.length;

    if (length < 4) return phoneNumber;
    if (length < 8) return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 7)}-${phoneNumber.slice(7, 10)}`;
  }

  const handlePhone = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    e.currentTarget.value = formatPhone(value);
  }

  useEffect(() => {
    if (page === 'profile') getMe();
  }, [page, getMe])

  return (
    <div className="page-content agent-page">
      <div>{title}</div>
      <form className="agent-form">
        <div className="container agent-inputs">
          {
            items.map(item => {
              const { name, label, type, readOnly } = item;
              return (
                <div key={name} className="agent-input">
                  <label htmlFor={name}>{label}</label>
                  <input
                    name={name}
                    type={type}
                    onChange={e => handleInputChange(e)}
                    onKeyDown={
                      name === 'phone' ? e => handlePhone(e) : undefined
                    }
                    readOnly={readOnly}
                    defaultValue={ readOnly ? me[name] : '' }
                    className={ readOnly ? 'read-only' : undefined }
                  />
                </div>
              )
            })
          }
        </div>
        <div className="container agent-error-messages">
          <div className="agent-error-message">{errorPW}</div>
          <div className="agent-error-message">{errorCheckPW}</div>
        </div>
        <div className="container agent-buttons">
          <button
            className="agent-button"
            onClick={handleSubmit}
            type="button">
              {submit}
          </button>
          {toggle ? <button
            className="agent-button"
            onClick={toggleSection}
            type="button">
              {toggle}
          </button> : null}
        </div>
      </form>
    </div>
  );
}

export default Agent;