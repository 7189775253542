import React, { useEffect, useState } from 'react';
import { useLazyQuery } from "@apollo/client";
import { USERS, USERS_COUNT } from '@/graphql/serverQueries';
import UsersTable from '@/containers/tables/UsersTable';
import { UsersInterface, UsersData, UsersFilterData } from '@/models/users';
import UsersFilters from '@/containers/filters/UsersFilters';
import { Stack, Button } from '@mui/material';
const PAGE_SIZE = 12;

function Users() {
  const [usersTablePage, setUsersTablePage] = useState(1);
  const [totalUsersCount, setTotalUsersCount] = useState(PAGE_SIZE);
  const [usersState, setUsersState] = useState<Array<UsersInterface> | null>(null);
  const [queryString, setQueryString] = useState('');

  const [filters, setFilters] = useState(new UsersFilterData());

  const onCompletedGetUsers = ({ users }: {
    users: {
      edges: { node: UsersInterface }[],
    }
  }) => {
    const { edges } = users || [];
    const data = UsersData.fromGraphql(edges);
    setUsersState(data);
  }

  const onCompletedGetUsersCount = ({ usersCount }: { usersCount:  number }) => {
    setTotalUsersCount(usersCount);
  }

  const [getUsers,] = useLazyQuery(USERS, {
    fetchPolicy: "network-only",
    onCompleted: onCompletedGetUsers
  });

  const [getUsersCount,] = useLazyQuery(USERS_COUNT, {
    fetchPolicy: "network-only",
    onCompleted: onCompletedGetUsersCount
  })

  useEffect(() => {
    setUsersTablePage(1);
  }, [filters]);

  useEffect(() => {
    const stateArray: string[] = [];
    filters.userType?.map(e => stateArray.push(e.type));

    const continentArray: string[] = [];
    filters.continent?.map(e => continentArray.push(e.continent))

    const userInput: {
      country: string | undefined,
      continents: string[] | undefined,
      states: string[] | undefined,
      c2modelIds: string[] | undefined,
      startDate: number | undefined,
      endDate: number | undefined
    } = {
      country: filters.country,
      continents: continentArray,
      states: stateArray,
      c2modelIds: filters.c2model,
      startDate: filters.startTime,
      endDate: filters.endTime
    }

    getUsers({
      variables: {
        first: PAGE_SIZE,
        offset: (usersTablePage - 1) * PAGE_SIZE,
        kwd: filters.kwd,
        userInput
      }
    });

    getUsersCount({
      variables: {
        kwd: filters.kwd,
        userInput
      }
    })
  }, [filters, getUsers, getUsersCount, usersTablePage]);

  useEffect(() => {
    let newQueryString = []
    if (filters.country) newQueryString.push(`country=${filters.country}`);
    if (filters.userType) newQueryString.push(`state=${filters.userType}`);
    if (filters.c2model) {
      newQueryString.push(`c2modelId=${filters.c2model}`);
    }

    newQueryString.length ? setQueryString(`?${newQueryString.join('&')}`) : setQueryString('');
  }, [filters]);

  const csvLink: string = `${process.env.REACT_APP_END_POINT}/export_users${queryString}`;

  return (
    <div className="page-content users-page">
      <Stack spacing={3} style={{ width: "100%" }}>
        <UsersFilters
          filters={filters}
          setFilters={setFilters}
        />
        <Stack direction="row" style={{ justifyContent: "space-between", alignItems: "center" }}>
          <div>TOTAL | {totalUsersCount}</div>
          <a href={csvLink}>
            <Button variant="outlined" style={{ height: "100%" }}>csv</Button>
          </a>
        </Stack>
        <UsersTable
          data={usersState}
          page={usersTablePage}
          setPage={setUsersTablePage}
          totalCount={totalUsersCount}
        />
      </Stack>
    </div>
  );
}

export default Users;
