import React, { useEffect }from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { ReactComponent as Logo } from "@image/logo-concise.svg";
import { AppBar, Toolbar } from '@mui/material';
import { CurSigninInfo } from '@/models/signinInfo';
import { IS_LOGGEDIN } from '@/graphql/clientQueries';
import DrawerMenu from "@/components/common/header/DrawerMenu";
import HeaderMenu from "@cpts/common/header/HeaderMenu";
import { currentSigninInfo } from '@/cache';

function Header() {
  const history = useHistory();
  const location = useLocation();
  const _loggedin = useQuery<CurSigninInfo>(IS_LOGGEDIN);
  
  useEffect(() => {
    const _token = localStorage.getItem('token');
    const _refreshExpiresIn = localStorage.getItem('refreshExpiresIn');
    const _username = localStorage.getItem('username');
    const _password = localStorage.getItem('password');

    if (_loggedin.data?.isLoggedin === null) {
      if (_token === null 
      || _refreshExpiresIn === null
      || _username === null
      || _password === null) {
        if (location.pathname !== '/login') {
          localStorage.clear();
          history.replace('/login');  
          return;
        }
      } else {
        currentSigninInfo({ token: _token, refreshExpiresIn: Number(_refreshExpiresIn), username: _username, password: _password });
      }
    }
    
    else if (location.pathname === "/" || location.pathname === '/login') {
      history.replace('/main');
      return;
    }
  }, [_loggedin, history, location]);

  return (
    <AppBar id="header">
      <Toolbar disableGutters>
        { _loggedin.data?.isLoggedin !== null && <DrawerMenu/> }
        <Link to="/">
          <div className="rabit-logo">
            <Logo />
          </div>
        </Link>
      </Toolbar>
      <div className="rabit-menu">
        { _loggedin.data?.isLoggedin !== null && <HeaderMenu/> }
      </div>
    </AppBar>      
  );
}

export default Header;