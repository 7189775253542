export interface MeInterface {
  [index: string]: string;
  email: string;
  name: string;
  phone: string;
}

interface AgentItemsInterface {
  name: string;
  label: string;
  type: string;
  readOnly: boolean;
}

interface AgentInterface {
  page: string;
  items: Array<AgentItemsInterface>;
  title: string;
  submit: string;
}

export class AgentData implements AgentInterface {
  page: string;
  items: Array<AgentItemsInterface>;
  title: string;
  submit: string;
  toggle: string;

  constructor(
    page: string = '',
    items: {
      name: string;
      label: string;
      type: string;
      readOnly: boolean;
    }[] = [
      {
        name: '',
        label: '',
        type: '',
        readOnly: false
      }
    ],
    title: string = '',
    submit: string = '',
    toggle: string = ''
  ) {
    this.page = page;
    this.items = items;
    this.title = title;
    this.submit = submit;
    this.toggle = toggle;
  }

  public static fromJson(json: Object): AgentData {
    return Object.assign(new AgentData(), json);
  }
}