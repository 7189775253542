import React, { Dispatch, SetStateAction, useState, ChangeEvent, useEffect } from 'react';
import { TextField, Stack, Button, FormControl, InputLabel, FormGroup, FormControlLabel, Checkbox, ButtonGroup } from '@mui/material';
import { useQuery } from '@apollo/client';
import { C2modelData } from '@/models/contracts';
import { ContinentClass, ContinentEnum, DateClass, DateEnum, UsersFilterCategoryData, UsersFilterData } from '@/models/users';
import { USERS_FILTERS } from '@/graphql/serverQueries';
import SearchAppBar from '@/components/common/Search';
import { styled } from '@mui/material/styles';
import { UserTypeClass, UserTypeEnum } from '@/models/userInfo';
import DatePicker from 'react-datepicker';
const ONE_HOUR = (60 * 60 * 1000);
const ONE_DAY = (24 * 60 * 60 * 1000);

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  position: "initial",
  transform: "none",
  lineHeight: "39px",
  marginRight: theme.spacing(2),
  width: 50
}));

function UsersFilters(
  {
    filters,
    setFilters
  }: {
    filters: UsersFilterData,
    setFilters: Dispatch<SetStateAction<UsersFilterData>>
  }
) {
  const [usersFilters, setUsersFilters] = useState(new UsersFilterCategoryData());

  useQuery(USERS_FILTERS, {
    fetchPolicy: "cache-first",
    onCompleted({ c2models, country }) {
      setUsersFilters(new UsersFilterCategoryData(country, undefined, C2modelData.fromGraphql(c2models.edges)));
    }
  });

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name, checked } = e.target;

    if (checked) {
      if (name === "userType") {
        const newUserType = filters.userType ? [...filters.userType!, (new UserTypeClass(value as UserTypeEnum))] : [(new UserTypeClass(value as UserTypeEnum))]
        setFilters(UsersFilterData.fromFilters({
          ...filters,
          userType: newUserType
        }));
      } else if (name === "continent") {
        const newContinent = filters.continent ? [...filters.continent!, (new ContinentClass(value as ContinentEnum))] : [(new ContinentClass(value as ContinentEnum))]
        setFilters(UsersFilterData.fromFilters({
          ...filters,
          continent: newContinent
        }));
      } else if (name === "c2model") {
        const newC2model = filters.c2model ? [...filters.c2model, value] : [value]
        setFilters(UsersFilterData.fromFilters({
          ...filters,
          c2model: newC2model
        }))
      }
    }
    else setFilters(UsersFilterData.fromFilters({
      ...filters,
      [name]: undefined
    }));
  }

  const handleButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    let newStartTime;
    const today = Date.now();

    switch (e.currentTarget.innerText) {
      case '오늘':
        newStartTime = today;
        break;
      case '1개월':
        newStartTime = today - (ONE_DAY * 30);
        break;
      case '3개월':
        newStartTime = today - (ONE_DAY * 90);
        break;
      case '6개월':
        newStartTime = today - (ONE_DAY * 180);
        break;
      case '1년':
        newStartTime = today - (ONE_DAY * 365);
        break;
      case '전체':
        newStartTime = 1617503194935; //2021년 4월 4일부터 전체 검색됨.
        break;
      default: break;
    }

    setFilters(UsersFilterData.fromFilters({
      ...filters,
      startTime: newStartTime,
      endTime: today
    }));
  }

  useEffect(() => {
    setFilters(UsersFilterData.fromFilters({
      ...filters,
      startTime: 1617503194935, //2021년 4월 4일부터 전체 검색됨.
      endTime: Date.now()
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) //컴포넌트 마운트 시에 1회만 실행

  const handleResetFilter = () => {
    setFilters(new UsersFilterData());
  }

  const numToDate = (name: 'startTime' | 'endTime', num: number | undefined) => {
    if (!num) return undefined;
    return new Date(num);
    // return name === 'startTime' ? new Date(num - (9 * ONE_HOUR)) : new Date(num - (33 * ONE_HOUR));
  }

  const handleSelectDate = (name: 'startTime' | 'endTime') => (date: Date | null) => {
    if (!date) {
      setFilters(UsersFilterData.fromFilters({
        ...filters,
        [name]: undefined
      }));
    }
    else {
      const time = name === 'startTime' ? date.getTime() + (9 * ONE_HOUR) : date.getTime() + (33 * ONE_HOUR);
      setFilters(UsersFilterData.fromFilters({
        ...filters,
        [name]: time
      }));
    }
  }

  return (
    <Stack direction="column" spacing={2} style={{
      justifyContent: "space-between",
      width: "100%",
      paddingLeft: 10,
      paddingRight: 10
    }}>
      <Stack direction="column" spacing={2}>
        <FormControl style={{
          flexDirection: "row",
          gap: 10
        }}>
          <StyledInputLabel>가입일자</StyledInputLabel>
          <FormControl>
            <DatePicker
              placeholderText="시작시간"
              selected={numToDate('startTime', filters.startTime)}
              onChange={handleSelectDate('startTime')}
              customInput={<TextField />}
              isClearable
            />
          </FormControl>
          <FormControl>
            <DatePicker
              placeholderText="끝시간"
              selected={numToDate('endTime', filters.endTime)}
              onChange={handleSelectDate('endTime')}
              customInput={<TextField />}
              isClearable
            />
          </FormControl>
          <ButtonGroup variant="outlined">
            {
              Object.values(DateEnum).filter(el => typeof el === 'number').map(el => {
                const range = new DateClass(el as DateEnum);
                return <Button onClick={(e) => handleButton(e)} key={range.label}>{range.label}</Button>
              })
            }
          </ButtonGroup>
        </FormControl>
        <FormControl style={{
          flexDirection: "row"
        }}>
          <StyledInputLabel>대륙</StyledInputLabel>
          <FormGroup style={{
            display: "flex",
            flexDirection: "row"
          }}>
            {
              usersFilters.continents.map((el: ContinentClass) => {
                return (
                  <FormControlLabel control={<Checkbox
                    onChange={handleCheckbox}
                    name="continent"
                    value={ContinentEnum[el.continent]}
                  />} label={el.label} key={el.continent}></FormControlLabel>
                )
              })
            }
          </FormGroup>
        </FormControl>
        <FormControl style={{
          flexDirection: "row"
        }}>
          <StyledInputLabel>회원상태</StyledInputLabel>
          <FormGroup style={{
            display: "flex",
            flexDirection: "row"
          }}>
            {
              usersFilters.userTypes.map((el: UserTypeClass) => {
                return (
                  <FormControlLabel control={<Checkbox
                    onChange={handleCheckbox}
                    name="userType"
                    value={UserTypeEnum[el.type]}
                  />} label={el.label} key={el.type}></FormControlLabel>
                )
              })
            }
          </FormGroup>
        </FormControl>
        <FormControl style={{
          flexDirection: "row"
        }}>
          <StyledInputLabel>라빗봇</StyledInputLabel>
          <FormGroup style={{
            display: "flex",
            flexDirection: "row"
          }}>
            {
              usersFilters.c2models.map((el: C2modelData) => {
                return (
                  <FormControlLabel control={<Checkbox
                    onChange={handleCheckbox}
                    name="c2model"
                    value={el.id}
                  />} label={el.label} key={el.id}></FormControlLabel>
                )
              })
            }
          </FormGroup>
        </FormControl>
      </Stack>
      <Stack direction="row" spacing={1} style={{
        justifyContent: "space-between",
        width: "100%"
      }}>
        <SearchAppBar
          filters={filters}
          setFilters={setFilters}
        />
        <Button variant="outlined" onClick={handleResetFilter}>reset</Button>
      </Stack>
    </Stack>
  )
}

export default UsersFilters;