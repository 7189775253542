import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IconButton, Stack } from '@mui/material';
import { SIGN_IN } from '@/graphql/serverMutations';
import { useMutation, useQuery } from '@apollo/client';
import { currentSigninInfo } from '@/cache';
import { Refresh } from "@material-ui/icons";
import { CurSigninInfo } from '@/models/signinInfo';
import { IS_LOGGEDIN } from '@/graphql/clientQueries';

function RefreshTimer() {
  const history = useHistory();
  const _refreshExpiresIn = localStorage.getItem("refreshExpiresIn");
  const [time, setTime] = useState(0);
  const [values, setValues] = useState({
    username: '',
    password: '',
  });
  const _loggedin = useQuery<CurSigninInfo>(IS_LOGGEDIN);  
  const [login, loginResult] = useMutation(SIGN_IN);

  const setTimer = (time: string) => {
    const now = Math.floor(Date.now() / 1000);
    const expire = Number(time);
    
    if (expire >= now) setTime(expire - now);
    else setTime(0);
  }

  // 만료 시간 세팅
  useEffect(() => {
    if (_refreshExpiresIn) setTimer(_refreshExpiresIn);
  }, [_refreshExpiresIn])

  // 타이머 & cleanup
  useEffect(() => {
    const countdown = setInterval(() => {
      if (time === 0) {
        clearInterval(countdown);
        alert("인증이 만료되었습니다. 다시 로그인해 주세요.");
        currentSigninInfo(null);
        localStorage.clear();
      } else {
        setTime(time - 1);
        return;
      }
    }, 1000);
    return () => clearInterval(countdown);
  }, [time]);

  const getTime = (unit: 'hours' | 'minutes' | 'seconds') => {
    let result;

    if (unit === 'hours') result = Math.floor(time / (60 * 60));
    else if (unit === 'minutes') result = Math.floor((time % (60 * 60)) / 60);
    else result = time % 60;

    return result < 10 ? `0${result}` : `${result}`;
  }

  // 토큰을 manually refresh 요청할 때
  useEffect(() => {
    if (loginResult.data?.signin) {
      localStorage.setItem('token', loginResult.data?.signin.token);
      localStorage.setItem('refreshExpiresIn', loginResult.data?.signin.refreshExpiresIn.toString());
      currentSigninInfo(Object.assign({}, loginResult.data?.signin, values));
      history.replace("/");
    } else if (loginResult.data?.signin === null) {
      alert("잘못된 요청입니다. 다시 로그인해 주세요.");
      currentSigninInfo(null);
      localStorage.clear();
    }
  }, [loginResult.data, history, values]);

  const handleClick = () => {
    if (_loggedin.data?.isLoggedin) {
      const { username, password } = _loggedin.data?.isLoggedin;
      setValues({ username, password });
      login({ variables: { username, password } });
      return;
    }
  }

  return (
    <Stack
      direction="row"
      style={{ alignItems: "center" }}
    >
      <div
        style={{ color: (time < 300) ? 'red' : 'white'}}
      >{getTime('hours')}:{getTime('minutes')}:{getTime('seconds')}</div>
      <IconButton
        aria-label="refresh token"
        onClick={handleClick}
        size="small"
        edge="end"
        style={{ color: (time < 300) ? 'red' : 'white'}}
      >
        <Refresh />
      </IconButton>
    </Stack>
  );
}

export default RefreshTimer;