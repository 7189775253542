import { ProviderEnum } from '@/models/users';
import { emailSplit, formatDate } from '@/utils/formatString';

export enum UserTypeEnum {
  BREAK = "BREAK",
  NEW = "NEW",
  COMMON = "COMMON",
  CONTRACT = "CONTRACT",
  PAUSE = "PAUSE",
  LEAVE = "LEAVE"
}

export class UserTypeClass {
  type: UserTypeEnum;
  
  constructor(type: UserTypeEnum = UserTypeEnum.COMMON) {
    this.type = type;
  }

  get label() {
    switch (this.type) {
      case UserTypeEnum.BREAK: return '이탈';
      case UserTypeEnum.NEW: return '신규';
      case UserTypeEnum.COMMON: return '일반';
      case UserTypeEnum.CONTRACT: return '계약';
      case UserTypeEnum.PAUSE: return '휴면';
      case UserTypeEnum.LEAVE: return '탈퇴';
      default: return '';
    }
  }
}

export class UserInfoData {
  id: string;
  email: string;
  phone: string;
  provider: ProviderEnum;
  joinDate: number;
  country: string;
  userType: UserTypeClass;
  lastOpenDate: number;

  constructor(
    id: string = '',
    email: string = '',
    phone: string = '',
    provider: ProviderEnum = ProviderEnum.GOOGLE,
    joinDate: number = 0,
    country: string = '',
    userType: UserTypeClass = new UserTypeClass(),
    lastOpenDate: number = 0
  ) {
    this.id = id;
    this.email = email;
    this.phone = phone;
    this.provider = provider;
    this.joinDate = joinDate;
    this.country = country;
    this.userType = userType;
    this.lastOpenDate = lastOpenDate;
  }

  get displayInfo() {
    return [
      { label: '가입일', value: formatDate(this.joinDate) },
      { label: '구분', value: this.userType.label},
      { label: '국가', value: this.country },
      { label: '최근 접속일', value: formatDate(this.lastOpenDate) },
      { label: '전화번호', value: this.phone },
      { label: `메일주소 (${this.provider})`, value: emailSplit(this.email)},
    ]
  }
}