import { C2modelData } from "./contracts";
import { UserTypeEnum, UserTypeClass } from "./userInfo";

export enum DateEnum {
  ONE_DAY,
  ONE_MONTH,
  THREE_MONTH,
  SIX_MONTH,
  ONE_YEAR,
  ALL
}

export class DateClass {
  range: DateEnum

  constructor(range: DateEnum = DateEnum.ONE_DAY) { this.range = range }

  get label() {
    switch (this.range) {
      case DateEnum.ONE_DAY: return '오늘';
      case DateEnum.ONE_MONTH: return '1개월';
      case DateEnum.THREE_MONTH: return '3개월';
      case DateEnum.SIX_MONTH: return '6개월';
      case DateEnum.ONE_YEAR: return '1년';
      case DateEnum.ALL: return '전체';
      default: return '';
    }
  }
}

export enum ProviderEnum {
  GOOGLE = "GOOGLE",
  FACEBOOK = "FACEBOOK",
  APPLE = "APPLE"
}

export enum ContinentEnum {
  ASIA = "ASIA",
  AMERICA = "AMERICA",
  EURO = "EURO",
  AFRICA = "AFRICA",
  ETC = "ETC"
}

export class ContinentClass {
  continent: ContinentEnum;

  constructor(continent: ContinentEnum = ContinentEnum.ASIA) {
    this.continent = continent;
  }

  get label() {
    switch (this.continent) {
      case ContinentEnum.ASIA: return '아시아';
      case ContinentEnum.AMERICA: return '아메리카';
      case ContinentEnum.AFRICA: return '아프리카';
      case ContinentEnum.EURO: return '유럽';
      case ContinentEnum.ETC: return '기타';
      default: return '';
    }
  }
}

export interface UsersInterface {
  id: string,
  joinDate: number,
  userType: UserTypeEnum,
  email: string,
  country: string,
  phone: string,
  latestContractDate: number,
  cumulativeContractCount: number,
  cumulativeStatementAmount: number
}

export class UsersData implements UsersInterface {
  id: string;
  joinDate: number;
  userType: UserTypeEnum;
  email: string;
  country: string;
  phone: string;
  latestContractDate: number;
  cumulativeContractCount: number;
  cumulativeStatementAmount: number;

  constructor(
    id: string = '',
    joinDate: number = Date.now(),
    userType: UserTypeEnum = UserTypeEnum.NEW,
    email: string = '',
    country: string = '',
    phone: string = '',
    latestContractDate: number = Date.now(),
    cumulativeContractCount: number = 0,
    cumulativeStatementAmount: number = 0,
  ) {
    this.id = id;
    this.joinDate = joinDate;
    this.userType = userType;
    this.email = email;
    this.country = country;
    this.phone = phone;
    this.latestContractDate = latestContractDate;
    this.cumulativeContractCount = cumulativeContractCount;
    this.cumulativeStatementAmount = cumulativeStatementAmount;
  }

  public static fromGraphql(graphql: { node: UsersInterface }[]): UsersData[] {
    return graphql.map(el => Object.assign({}, el.node));
  }

  public static columnTitles() {
    const arr = Object.keys(new UsersData()).slice(1);
    return arr.map((el) => {
      switch (el) {
        case 'email':
          return '이메일';
        case 'phone':
          return '전화번호';
        case 'country':
          return '국가';
        case 'joinDate':
          return '가입일';
        case 'userType':
          return '구분';
        case 'latestContractDate':
          return '최신계약일';
        case 'cumulativeContractCount':
          return '누적계약 수';
        case 'cumulativeStatementAmount':
          return '누적 결제금액(USDT)';
        default:
          return '';
      }
    });
  }
}

interface UsersFilterCategoryInterface {
  countries: Array<String>,
  userTypes: Array<UserTypeClass>,
  c2models: Array<C2modelData>
}

export class UsersFilterCategoryData implements UsersFilterCategoryInterface {
  countries: Array<string>;
  userTypes: Array<UserTypeClass>;
  c2models: Array<C2modelData>;
  continents: Array<ContinentClass>;

  constructor(
    countries: Array<string> = [''],
    userTypes: Array<UserTypeClass> = Object.values(UserTypeEnum).filter(el => typeof el === 'string').map(el => new UserTypeClass(el as UserTypeEnum)),
    c2models: Array<C2modelData> = [new C2modelData()],
    continents: Array<ContinentClass> = Object.values(ContinentEnum).filter(el => typeof el === 'string').map(el => new ContinentClass(el as ContinentEnum))
  ) {
    this.countries = countries;
    this.userTypes = userTypes;
    this.c2models = c2models;
    this.continents = continents;
  }
}

interface UsersFilterInterface {
  country: string | undefined,
  userType: UserTypeClass[] | undefined,
  c2model: string[] | undefined,
  kwd: string | undefined,
  continent: ContinentClass[] | undefined,
  startTime: number | undefined,
  endTime: number | undefined
}

export class UsersFilterData implements UsersFilterInterface {
  [index: string]: string | UserTypeClass[] | string[] | ContinentClass[] | number | undefined;
  country: string | undefined;
  userType: UserTypeClass[] | undefined;
  c2model: string[] | undefined;
  kwd: string | undefined;
  continent: ContinentClass[] | undefined;
  startTime: number | undefined;
  endTime: number | undefined;

  constructor(
    country: string | undefined = undefined,
    userType: UserTypeClass[] | undefined = undefined,
    c2model: string[] | undefined = undefined,
    kwd: string | undefined = undefined,
    continent: ContinentClass[] | undefined = undefined,
    startTime: number | undefined = Date.now(),
    endTime: number | undefined = Date.now()
  ) {
    this.country = country;
    this.userType = userType;
    this.c2model = c2model;
    this.kwd = kwd;
    this.continent = continent;
    this.startTime = startTime;
    this.endTime = endTime;
  }

  public static fromFilters(filters: UsersFilterInterface): UsersFilterData {
    return new UsersFilterData(...Object.values(filters));
  }
}