import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Stack, Button, FormControl, Select, SelectChangeEvent } from '@mui/material';
import { useQuery, useLazyQuery } from '@apollo/client';
import { DateRangeEnum } from '@/models/userGraph';
import { C2modelData } from '@/models/contracts';
import { ContractBarGraphData, ContractBarGraphIntervalData } from '@/models/contractGraph';
import { C2MODEL_FILTER, CONTRACT_BAR_GRAPH } from '@/graphql/serverQueries';

function GraphIntervalFilters(
  {
    handleClickDateFilter,
    renderSelectedFilter,
    handleSelectC2model,
    c2ModelState,
    c2ModelFilter
  }: {
    handleClickDateFilter: (value: DateRangeEnum) => () => void,
    renderSelectedFilter: (value: DateRangeEnum) => "outlined" | "contained",
    handleSelectC2model?: (e: SelectChangeEvent<string>) => void,
    c2ModelState?: string,
    c2ModelFilter?: C2modelData[]
  }
) {
  return (
    <Stack direction="row" style={{ maxWidth: 1000, width: "100%", justifyContent: "space-between", alignItems: "center" }}>
      <Stack direction="row" spacing={2}>
        {
          Object.values(DateRangeEnum).map(value => {
            return (
              <Button
                key={value}
                onClick={handleClickDateFilter(value)}
                variant={renderSelectedFilter(value)}
                style={{ width: "80px", height: "40px" }}
              >{value}</Button>
            )
          })
        }
      </Stack>
      {
        handleSelectC2model
        ? <FormControl>
          <Select
            native
            labelId="label"
            onChange={handleSelectC2model}
            defaultValue={c2ModelState}
            variant="outlined"
          >
            {
              c2ModelFilter?.map((el: C2modelData) => {
                return <option value={el.id} key={el.id}>{el.label}</option>
              })
            }
          </Select>
        </FormControl>
        : <Button
          onClick={() => alert('준비중인 기능입니다.')}
          variant="outlined"
          style={{ width: "80px", height: "40px" }}
        >CSV</Button>
      }
    </Stack>
  )
}

export function ContractBarGraphIntervalFilters(
  {
    dateRange,
    setDateRange,
    setContractBarGraph
  }: {
    dateRange: DateRangeEnum,
    setDateRange: Dispatch<SetStateAction<DateRangeEnum>>,
    setContractBarGraph: Dispatch<SetStateAction<ContractBarGraphData[]>>
  }
) {
  const [c2ModelFilter, setC2ModelFilter] = useState([new C2modelData()]);
  const [c2ModelState, setC2ModelState] = useState('QzJNb2RlbDo1');

  useQuery(C2MODEL_FILTER, {
    variables: { isRunning: true },
    fetchPolicy: "cache-first",
    onCompleted({ c2models }) {
      setC2ModelFilter(C2modelData.fromGraphql(c2models.edges));
    }
  });

  const [getContractBarGraph, ] = useLazyQuery(CONTRACT_BAR_GRAPH, {
    fetchPolicy: "network-only",
    onCompleted: ({ contractBarGraph }) => setContractBarGraph(ContractBarGraphData.fromGraphql(contractBarGraph[0].items))
  });

  const handleClickDateFilter = (value: DateRangeEnum) => () => setDateRange(value);
  const renderSelectedFilter = (value: DateRangeEnum) => value === dateRange ? "contained" : "outlined";
  const handleSelectC2model = (e: SelectChangeEvent<string>) => {if (setC2ModelState) setC2ModelState(e.target.value);}

  useEffect(() => {
    const today = Date.now();
    let _dateRange: ContractBarGraphIntervalData;

    if (dateRange === '1W') {
      _dateRange = new ContractBarGraphIntervalData(today, 8)
    } else if (dateRange === '1M') {
      _dateRange = new ContractBarGraphIntervalData(today, 31)
    } else if (dateRange === '1Y') {
      _dateRange = new ContractBarGraphIntervalData(today, 365)
    } else if (dateRange === '3Y') {
      _dateRange = new ContractBarGraphIntervalData(today, 1095)
    } else {
      return;
    }
    const variables = {
      startDate: _dateRange.startDate,
      endDate: _dateRange.endDate,
      interval: _dateRange.interval,
      c2modelId: c2ModelState
    }

    getContractBarGraph({ variables })
  }, [dateRange, c2ModelState, getContractBarGraph]);

  return (
    <GraphIntervalFilters
      handleClickDateFilter={handleClickDateFilter}
      renderSelectedFilter={renderSelectedFilter}
      handleSelectC2model={handleSelectC2model}
      c2ModelState={c2ModelState}
      c2ModelFilter={c2ModelFilter}
    />
  )
}

export function BarGraphIntervalFilters(
  {
    dateRange,
    setDateRange
  }: {
    dateRange: DateRangeEnum,
    setDateRange: Dispatch<SetStateAction<DateRangeEnum>>
  }
) {
  const handleClickDateFilter = (value: DateRangeEnum) => () => setDateRange(value);
  const renderSelectedFilter = (value: DateRangeEnum) => value === dateRange ? "contained" : "outlined";
  return (
    <GraphIntervalFilters
      handleClickDateFilter={handleClickDateFilter}
      renderSelectedFilter={renderSelectedFilter}
    />
  )
}