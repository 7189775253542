import { CurrencyEnum } from "./tradeHistory";

enum ContractStateEnum {
  INIT = "INIT",
  ACTIVE = "ACTIVE",
  STOP_PX = "STOP_PX",
  NONPAYMENT = "NONPAYMENT",
  INACTIVE = "INACTIVE",
  NOPERMISSION = "NOPERMISSION",
  INVALIDOP = "INVALIDOP",
  LOSS = "LOSS",
  LIQUIDATION = "LIQUIDATION",
  CLEAR = "CLEAR",
  REQPAYMENT = "REQPAYMENT",
  END = "END"
}

interface C2modelInterface {
  id: string,
  isRunning: boolean,
  strategyName: string
}

export class C2modelData implements C2modelInterface {
  id: string;
  isRunning: boolean;
  strategyName: string;
  currency: CurrencyEnum

  constructor(
    id: string = '',
    isRunning: boolean = true,
    strategyName: string = '',
    currency: CurrencyEnum = CurrencyEnum.BTC
  ) {
    this.id = id;
    this.isRunning = isRunning;
    this.strategyName = strategyName;
    this.currency = currency;
  }

  get label(): string {
    return this.isRunning ? this.strategyName : `${this.strategyName} (end)`
  }

  public static fromGraphql(graphql: { node: C2modelInterface }[]): C2modelData[] {
    return graphql.map(el => Object.assign(new C2modelData(), el.node));
  }
}

export interface ContractsInterface {
  [index: string]: any,
  id: string,
  cid: string,
  state: ContractStateEnum,
  c2modelName: string,
  c2modelSymbols: Array<string>,
  startDate: number,
  endDate: number,
  rate: number
}

export class ContractsData implements ContractsInterface {
  [index: string]: any;
  id: string;
  cid: string;
  state: ContractStateEnum;
  c2modelName: string;
  c2modelSymbols: Array<string>;
  startDate: number;
  endDate: number;
  rate: number;

  constructor(
    id: string = '',
    cid: string = '',
    state: ContractStateEnum = ContractStateEnum.ACTIVE,
    c2modelName: string = '',
    c2modelSymbols: Array<string> = [''],
    startDate: number = 0,
    endDate: number = 0,
    rate: number = 0
  ) {
    this.id = id;
    this.cid = cid;
    this.state = state;
    this.c2modelName = c2modelName;
    this.c2modelSymbols = c2modelSymbols;
    this.startDate = startDate;
    this.endDate = endDate;
    this.rate = rate;
  }

  get formattedRate(): string {
    return `${this.rate}%`;
  }

  get formattedState(): string {
    switch (this.state) {
      case ContractStateEnum.INIT: return `준비중 (${this.state})`;
      case ContractStateEnum.ACTIVE: return `운용중 (${this.state})`;
      case ContractStateEnum.STOP_PX: return `운용중 (${this.state})`;
      case ContractStateEnum.NONPAYMENT: return `미납 (${this.state})`;
      case ContractStateEnum.INACTIVE: return `해지권고 (${this.state})`;
      case ContractStateEnum.NOPERMISSION: return `해지권고 (${this.state})`;
      case ContractStateEnum.INVALIDOP: return `해지권고 (${this.state})`;
      case ContractStateEnum.LOSS: return `해지중 (${this.state})`;
      case ContractStateEnum.CLEAR: return `해지중 (${this.state})`;
      case ContractStateEnum.REQPAYMENT: return `운용중 (${this.state})`;
      case ContractStateEnum.END: return `해지완료 (${this.state})`;

      default: return '';
    }
  }

  private formatDate(date: number): string {
    if (!date) return '-';

    return new Date(date).toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  }

  get formattedStartDate(): string {
    return this.formatDate(this.startDate);
  }

  get formattedEndDate(): string {
    return this.formatDate(this.endDate);
  }

  public static fromGraphql(graphql: { node: ContractsInterface }[]): ContractsData[] {
    return graphql.map(el => Object.assign(new ContractsData(), el.node));
  }
}