import React, { Dispatch, SetStateAction, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { InputBase, Button, InputLabel } from '@mui/material';
import { UsersFilterData } from '@/models/users';

const Search = styled('div')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  display: 'flex',
  gap: theme.spacing(1)
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 1),
  },
  borderRadius: theme.shape.borderRadius,
  border: '1px solid',
  borderColor: alpha(theme.palette.primary.light, 0.5)
}));

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  overflow: "visible",
  lineHeight: "38px",
  marginRight: theme.spacing(2),
  width: 50
}))


function SearchAppBar(
  {
    filters,
    setFilters
  }: {
    filters: UsersFilterData,
    setFilters: Dispatch<SetStateAction<UsersFilterData>>
  }
) {
  const [search, setSearch] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setSearch(value);
  }

  const handleSearch = () => {
    setFilters({
      ...filters,
      kwd: search
    })
  }

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key === 'Enter') handleSearch();
  }

  return (
    <Search>
      <StyledInputLabel>검색어</StyledInputLabel>
      <StyledInputBase
        placeholder="Search..."
        inputProps={{ 'aria-label': 'search' }}
        onKeyDown={handleEnter}
        onChange={handleInputChange}
      />
      <Button variant="outlined" onClick={handleSearch}>search</Button>
    </Search>
  );
}

export default SearchAppBar;
