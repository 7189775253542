import React, { useState } from 'react';
import { CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, Line, ComposedChart } from "recharts";
import { ContractBarGraphData } from '@/models/contractGraph';
import { Stack, useTheme } from '@mui/material';
import { DateRangeEnum } from '@/models/userGraph';
import { ContractBarGraphIntervalFilters } from '../filters/GraphIntervalFilters';
function BarGraph( { data, dateRange } : { data: ContractBarGraphData[], dateRange: DateRangeEnum }) {
  const theme = useTheme();

  const tickFormatter =  (timestamp: number) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    if (dateRange === DateRangeEnum.ONE_WEEK || dateRange === DateRangeEnum.ONE_MONTH) return `${month < 10 ? `0${month}` : month}/${day}`;
    else return `${year}.${month < 10 ? `0${month}` : month}`;
  }

  const labelFormatter =  (timestamp: number) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    if (dateRange === DateRangeEnum.ONE_WEEK || dateRange === DateRangeEnum.ONE_MONTH) return `${year}/${month < 10 ? `0${month}` : month}/${day}`;
    else return `${year}.${month < 10 ? `0${month}` : month}`;
  }
  
  return (
    <ComposedChart
      width={1000}
      height={420}
      data={data}
      margin={{ top: 20, right: 20 }}
    >
      <CartesianGrid strokeDasharray="1 2" />
      <XAxis dataKey="timestamp" tickFormatter={tickFormatter}  angle={340} />
      <YAxis yAxisId="left" />
      <YAxis yAxisId="right" orientation="right" />
      <Tooltip labelFormatter={labelFormatter}/>
      <Legend wrapperStyle={{ position: "initial" }}/>
      <Bar name="해지" yAxisId="left" dataKey="endContractCount" stackId="a" fill={theme.palette.error.light} />
      <Bar name="운용" yAxisId="left" dataKey="currentContractCount" stackId="a" fill={theme.palette.primary.light} />
      <Bar name="신규" yAxisId="left" dataKey="newContractCount" stackId="a" fill={theme.palette.secondary.light} />
      <Line name="aum" yAxisId="right" type="linear" dataKey="aum" stroke="black" dot={false} strokeWidth={2} />
    </ComposedChart>
  )
}

function ContractBarGraph() {
  const [dateRange, setDateRange] = useState(DateRangeEnum.ONE_MONTH);
  const [contractBarGraph, setContractBarGraph] = useState([
    new ContractBarGraphData()
  ]);

  return (
    <Stack style={{ alignItems: "center" }}>
      <ContractBarGraphIntervalFilters
        dateRange={dateRange}
        setDateRange={setDateRange}
        setContractBarGraph={setContractBarGraph}
      />
      <BarGraph
        data={contractBarGraph}
        dateRange={dateRange}
      />
    </Stack>

  )
}

export default ContractBarGraph;