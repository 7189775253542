import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { DetailedStatementsData } from '@/models/detailedStatements';

function DetailedStatementsTable(
  {
    data
  }: {
    data: Array<DetailedStatementsData>
  }
) {
  const columns = [
    { title: '결제일', field: 'time' },
    { title: '결제상태', field: 'billingStatus' },
    { title: '결제수단', field: 'maskedCardInfo' },
    { title: '결제금액 (USD)', field: 'formattedAmount' },
    { title: '수수료', field: 'formattedFee' },
    { title: '라빗봇', field: 'botName' }
  ]
  
  const isFailed = (title: string, row: DetailedStatementsData) => {
    return (title === '결제상태' || title === '수수료') && row.billingStatus === 'Failed';
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(column => {
              return <TableCell key={column.title} align="center">{column.title}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data.map(row => {
              return (
                <TableRow key={row.id}>
                  {
                    columns.map(column => {
                      return isFailed(column.title, row)
                      ? <TableCell key={column.title} align="center" style={{ color: 'red' }} >{row[column.field]}</TableCell>
                      : <TableCell key={column.title} align="center" >{row[column.field]}</TableCell>
                    })
                  }
                </TableRow>
              )
            })
          }
          {
            data[0] && data[0].amount >= 0
            ? null
            : <TableRow>
              <TableCell colSpan={8} align="center">No records to display</TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DetailedStatementsTable;