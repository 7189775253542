import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Paper, OutlinedInput, InputAdornment, IconButton } from '@material-ui/core';
import { CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useMutation } from '@apollo/client';
import { SIGN_IN } from '@/graphql/serverMutations';
import { SigninInfo } from '@/models/signinInfo';
import { currentSigninInfo } from '@/cache';

interface SigninVars {
  username: string;
  password: string;
}

interface SigninResult {
  signin: SigninInfo | null;
}

function Login() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState<SigninVars>({
    username: '',
    password: '',
  });

  const [login, loginResult] = useMutation<SigninResult, SigninVars>(SIGN_IN);

  const handleChange = (prop: keyof SigninVars) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    setLoading(false);
    if (loginResult.data?.signin) {
      localStorage.setItem('token', loginResult.data?.signin.token);
      localStorage.setItem('refreshExpiresIn', loginResult.data?.signin.refreshExpiresIn.toString());
      currentSigninInfo(Object.assign({}, loginResult.data?.signin, values));
      alert("로그인 성공");
      history.replace("/");
    }
  }, [loginResult.data, history, values]);

  useEffect(() => {
    if (loginResult.data?.signin === null) {
      alert("아이디 또는 비밀번호를 잘못 입력했습니다.");
    }
  }, [loginResult.data])

  function handleClick() {
    setLoading(true);
    localStorage.setItem('username', values.username);
    localStorage.setItem('password', values.password);
    login({ variables: values });
  }

  return (
    <div className="page-content login-page">
    <Paper id="login-box" elevation={3}>
      <h1 style={{ fontSize: 36 }}>RABIT Admin</h1>
      <form style={{ width : "100%" }}>
        <OutlinedInput 
          required 
          fullWidth 
          id="id" 
          placeholder='Email'
          autoComplete='username email'
          type={'text'}
          value={values.username}
          onChange={handleChange('username')}
          style={{ marginBottom: 20 }}
        />
        <OutlinedInput 
          required 
          fullWidth
          autoComplete='current-password'
          id="pwd" 
          placeholder='Password'
          type={showPassword ? 'text' : 'password'}
          value={values.password}
          onChange={handleChange('password')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </form>
      <LoadingButton
        onClick={handleClick}
        loading={loading}
        loadingPosition="center"
        loadingIndicator={<CircularProgress color="inherit" size={16} style={{color: "#4A46B8"}} />}
        variant="contained"
        style={{ width: "100%", backgroundColor: loading ? '#B7B5DE' : '#4A46B8', height: "50px", color: '#ffffff' }}
      >
        Sign in
      </LoadingButton>
    </Paper>
    </div>
  );
}

export default Login;