import React,
{ useState }from 'react';
import { Link } from "react-router-dom";
import { IconButton, Drawer, Stack } from '@mui/material';
import { Menu } from '@mui/icons-material';

function DrawerMenu() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <div id="drawer">
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="open drawer"
        sx={{ mr: 1 }}
        onClick={() => setDrawerOpen(true)}
      >
        <Menu/>
      </IconButton>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Stack
          style={{
            height: '100%',
            backgroundColor: '#4A46B8',
            color: '#ffffff',
            padding: 50
          }}
        >
          <Link to="/users">Users</Link>
        </Stack>
      </Drawer>
    </div>
  );
}

export default DrawerMenu;