import React, { Fragment, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useQuery } from '@apollo/client';
import RefreshTimer from "@cpts/common/header/RefreshTimer";
import { currentSigninInfo } from '@/cache';
import { MY_NAME } from '@/graphql/serverQueries';
import { Stack } from '@mui/material';

function HeaderMenu() {
  const [curAgentName, setCurAgentName] = useState('');

  const handleSignout = () => {
    currentSigninInfo(null);
    localStorage.clear();
  }

  const myName = useQuery(MY_NAME);

  useEffect(() => {
    if (myName.data?.me.name && myName.data?.me.name.length) {
      setCurAgentName(myName.data?.me.name);
    } else if (myName.data?.me.email) {
      setCurAgentName(myName.data?.me.email);
    }
  }, [myName.data]);
  
  return (
    <Fragment>
      <Stack
        direction="row"
        spacing={3}
        style={{ alignItems: "center" }}
        sx={{ ml: 3 }}
      >
        <div>{`${curAgentName} 님, 안녕하세요`}</div>
        <RefreshTimer />
        <Link to="/profile">Profile</Link>
        <Link to="/" onClick={handleSignout}>Sign out</Link>
      </Stack>
    </Fragment>
  );
}

export default HeaderMenu;
