import React from 'react';

function Footer() {
  return (
    <footer id="footer">
      <div id="copyright">© 2021 Quantfolio Pte. Inc. All rights reserved.</div>
    </footer>
  );
}

export default Footer;
