interface StatementBarGraphInterface {
  timestamp: number,
  amount: number
}

interface StatementTableInterface {
  [index: string]: any;
  total: number,
  paid: number,
  unpaid: number
}

export class StatementBarGraphData implements StatementBarGraphInterface {
  timestamp: number;
  amount: number;

  constructor(
    timestamp: number = 0,
    amount: number = 0
  ) {
    this.timestamp = timestamp;
    this.amount = amount;
  }

  public static fromGraphql(graphql: StatementBarGraphInterface[]): StatementBarGraphData[] {
    return graphql.map(el => Object.assign(new StatementBarGraphData(), el));
  }
}

export class StatementTableData implements StatementTableInterface {
  [index: string]: any;
  total: number;
  paid: number;
  unpaid: number;

  constructor(
    total: number = 0,
    paid: number = 0,
    unpaid: number = 0
  ) {
    this.total = total;
    this.paid = paid;
    this.unpaid = unpaid;
  }

  public static fromGraphql(graphql: StatementTableInterface): StatementTableData {
    return Object.assign(new StatementTableData(), graphql);
  }
}