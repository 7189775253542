import { Fragment } from "react";
// pages
import User from "@page/User";
import Landing from "@page/Landing";
import Login from "@page/Login";
import Agent from "@page/Agent";
import Profile from "@page/Profile";
import Users from "@page/Users";
import Main from "@page/Main";
// components
import Header from "@cpts/common/header/index";
import Footer from "@cpts/common/Footer";


import { AgentData } from "@/models/agent";
import { signup } from "@/assets/json/agent.json";


const signupData = AgentData.fromJson(signup);

class RouteComponent{
  name : string;
  path: string;
  pageContent: JSX.Element;

  constructor(name: string, path: string, pageContent: JSX.Element) {
    this.name  = name;
    this.path = path;
    this.pageContent = pageContent;
  }

  component = () => ViewPage(this.pageContent);
}

function ViewPage(componentToPassDown: JSX.Element) {
  
  return(
    <Fragment>
        <Header/>
        {componentToPassDown}
        <Footer/>
    </Fragment>
  );
}

const routes: Array<RouteComponent> = [
  new RouteComponent( 'landing', "/", <Landing />),
  new RouteComponent( 'login', "/login", <Login />),
  new RouteComponent( 'main', "/main", <Main />),
  new RouteComponent( 'users', "/users", <Users />),
  new RouteComponent( 'register', "/register", <Agent agentData={signupData} />),
  new RouteComponent( 'user', "/user/*", <User />),
  new RouteComponent( 'profile', "/profile", <Profile />),
];

export default routes;