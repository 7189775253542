import React, { Dispatch, SetStateAction } from 'react';
import { Button, Select, TextField, InputLabel, FormControl, Stack, SelectChangeEvent } from '@mui/material';
import DatePicker from 'react-datepicker';
import { TradeHistoryFilterData, TradeHistorySideEnum } from '@/models/tradeHistory';
const ONE_HOUR = (60 * 60 * 1000);

function TradeHistoryFilters(
  {
    cid,
    c2modelSymbols,
    filters,
    setFilters,
    setPage
  }: {
    cid: string,
    c2modelSymbols: Array<string>,
    filters: TradeHistoryFilterData,
    setFilters: Dispatch<SetStateAction<TradeHistoryFilterData>>,
    setPage: Dispatch<SetStateAction<number>>
  }
) {
  const handleSelectFilter = (e: SelectChangeEvent<string>) => {
    const { value, name } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  }

  const handleSelectDate = (name: 'startTime' | 'endTime') => (date: Date | null) => {
    if (!date) {
      setFilters({
        ...filters,
        [name]: undefined
      });
    }
    else {
      const time = name === 'startTime' ? date.getTime() + (9 * ONE_HOUR) : date.getTime() + (33 * ONE_HOUR);
      setFilters({
        ...filters,
        [name]: time
      });
    }
  }

  const numToDate = (name: 'startTime' | 'endTime', num: number | undefined) => {
    if (!num) return undefined;
    return name === 'startTime' ? new Date(num - (9 * ONE_HOUR)) : new Date(num - (33 * ONE_HOUR));
  }
  
  const handleResetFilter = () => {
    setFilters(new TradeHistoryFilterData());
    setPage(1);
  }

  return (
    <Stack direction="row" style={{ justifyContent: "space-between" }}>
      <Stack direction="row" spacing={1}>
      <FormControl>
        <InputLabel id="ticker-label">Ticker</InputLabel>
        <Select
          native
          name="symbol"
          onChange={handleSelectFilter}
          value={filters.symbol}
          variant="outlined"
          labelId="ticker-label"
        >
          <option value=""></option>
          {
            c2modelSymbols.map(el => <option key={el} value={el}>{el}</option>)
          }
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel id="side-label">Side</InputLabel>
        <Select
          native
          name="side"
          onChange={handleSelectFilter}
          value={filters.side}
          variant="outlined"
          labelId="side-label"
        >
          <option value=""></option>
          {
            Object.keys(TradeHistorySideEnum).map(el => <option key={el} value={el}>{el}</option>)
          }
        </Select>
      </FormControl>
      <FormControl>
        <DatePicker
          placeholderText="시작시간"
          selected={numToDate('startTime', filters.startTime)}
          onChange={handleSelectDate('startTime')}
          customInput={<TextField />}
          isClearable
        />
      </FormControl>
      <FormControl>
        <DatePicker
          placeholderText="끝시간"
          selected={numToDate('endTime', filters.endTime)}
          onChange={handleSelectDate('endTime')}
          customInput={<TextField />}
          isClearable
        />
      </FormControl>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Button variant="outlined" onClick={handleResetFilter}>reset</Button>
        <a href={`https://cs.rabit.bot/export_history/${cid}`}>
          <Button variant="outlined" style={{ height: "100%" }}>csv</Button>
        </a>
      </Stack>
    </Stack>
  )
}

export default TradeHistoryFilters;