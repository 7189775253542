import React, { useEffect, useState } from 'react';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from "recharts";
import { DateRangeEnum } from '@/models/userGraph';
import { StatementBarGraphData } from '@/models/statementGraph';
import { Stack, useTheme } from '@mui/material';
import { BarGraphIntervalFilters } from '../filters/GraphIntervalFilters';
import { useLazyQuery } from '@apollo/client';
import { STATEMENT_BAR_GRAPH } from '@/graphql/serverQueries';
import { ContractBarGraphIntervalData } from '@/models/contractGraph';

function BarGraph( { data, dateRange } : { data: StatementBarGraphData[], dateRange: DateRangeEnum }) {
  const theme = useTheme();

  const tickFormatter =  (timestamp: number) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    if (dateRange === DateRangeEnum.ONE_WEEK || dateRange === DateRangeEnum.ONE_MONTH) return `${month < 10 ? `0${month}` : month}/${day}`;
    else return `${year}.${month < 10 ? `0${month}` : month}`;
  }

  const labelFormatter =  (timestamp: number) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    if (dateRange === DateRangeEnum.ONE_WEEK || dateRange === DateRangeEnum.ONE_MONTH) return `${year}/${month < 10 ? `0${month}` : month}/${day}`;
    else return `${year}.${month < 10 ? `0${month}` : month}`;
  }
  
  return (
    <BarChart
      width={800}
      height={400}
      data={data}
      margin={{ right: 20 }}
    >
      <CartesianGrid strokeDasharray="1 2" />
      <XAxis dataKey="timestamp" tickFormatter={tickFormatter} angle={340} />
      <YAxis />
      <Tooltip labelFormatter={labelFormatter}/>
      <Legend wrapperStyle={{ position: "initial" }}/>
      <Bar name="매출" dataKey="amount" stackId="a" fill={theme.palette.primary.light} />
    </BarChart>
  )
}

function StatementBarGraph() {
  const [statementBarGraph, setStatementBarGraph] = useState([
    new StatementBarGraphData()
  ]);
  const [dateRange, setDateRange] = useState(DateRangeEnum.ONE_MONTH);

  const [getStatementBarGraph, ] = useLazyQuery(STATEMENT_BAR_GRAPH, {
    fetchPolicy: 'network-only',
    onCompleted: ({ statements }) => {
      setStatementBarGraph(StatementBarGraphData.fromGraphql(statements))
    }
  })

  useEffect(() => {
    const today = Date.now();
    let _dateRange: ContractBarGraphIntervalData;

    if (dateRange === '1W') {
      _dateRange = new ContractBarGraphIntervalData(today, 8)
    } else if (dateRange === '1M') {
      _dateRange = new ContractBarGraphIntervalData(today, 31)
    } else if (dateRange === '1Y') {
      _dateRange = new ContractBarGraphIntervalData(today, 365)
    } else if (dateRange === '3Y') {
      _dateRange = new ContractBarGraphIntervalData(today, 1095)
    } else {
      return;
    }
    getStatementBarGraph({
      variables: {
        startDate: _dateRange.startDate,
        endDate: _dateRange.endDate,
        interval: _dateRange.interval
      }
    });
  }, [dateRange, getStatementBarGraph]);

  return (
    <Stack spacing={3}>
      <BarGraphIntervalFilters
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
      <BarGraph
        data={statementBarGraph}
        dateRange={dateRange}
      />
    </Stack>
  )
}

export default StatementBarGraph;