import React, { Dispatch, SetStateAction } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination } from '@mui/material';
import { ContractsData } from "@/models/contracts";
const PAGE_SIZE = 5;

function ContractsTable(
  {
    data,
    totalCount,
    page,
    setPage,
    setContractState
  }: {
    data: Array<ContractsData>,
    totalCount: number,
    page: number,
    setPage: Dispatch<SetStateAction<number>>,
    setContractState: Dispatch<SetStateAction<ContractsData>>
  }
) {
  const columns = [
    { title: '계약일', field: 'formattedStartDate'},
    { title: '해지일', field: 'formattedEndDate'},
    { title: '계약번호', field: 'cid'},
    { title: '라빗봇', field: 'c2modelName'},
    { title: '평가수익률', field: 'formattedRate'},
    { title: '상태', field: 'formattedState'},
    { title: '매매이력', }
  ]

  const isNegative = (title: string, row: ContractsData) => {
    return (title === '평가수익률' && row.rate < 0);
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(column => {
              return <TableCell key={column.title} align="center">{column.title}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data.map(row => {
              return (
                <TableRow key={row.id}>
                  {
                    columns.map(column => {
                      if (!column.field) return <TableCell key={column.title} align="center" onClick={() => setContractState(row)}>
                        <button>조회</button>
                      </TableCell>
                      else if (isNegative(column.title, row)) return <TableCell key={column.title} align="center" style={{ color: "red" }}>{row[column.field]}</TableCell>
                      else return <TableCell key={column.title} align="center" >{row[column.field]}</TableCell>
                    })
                  }
                </TableRow>
              )
            })
          }
          <TableRow>
            <TableCell colSpan={8} align="center">
              {
                data[0] && data[0].c2modelName.length
                ? <Pagination
                  count={Math.ceil(totalCount / PAGE_SIZE)}
                  onChange={(e, page) => setPage(page)}
                  style={{ width: '100%' }}
                  page={page}
                />
                : "No records to display"
              }
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ContractsTable;