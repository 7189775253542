import React, { Dispatch, SetStateAction } from 'react';
import { useHistory } from "react-router-dom";
import { UsersInterface, UsersData } from '@/models/users';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination } from '@mui/material';
import { UserTypeEnum } from '@/models/userInfo';
import { emailSplit } from '@/utils/formatString';
const PAGE_SIZE = 12;

function UsersTable({ data, totalCount, page, setPage }: {
  data: Array<UsersInterface> | null,
  totalCount: number,
  page: number,
  setPage: Dispatch<SetStateAction<number>>
}) {
  const history = useHistory();
  const formatDate = (date: number) => {
    if (!date) return '-';

    const dateObj = new Date(date);

    return dateObj.toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  };

  const pageCount = Math.ceil(totalCount / PAGE_SIZE);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={5}>
              회원정보
            </TableCell>
            <TableCell align="center" colSpan={5}>
              계약정보
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>NO</TableCell>
            {UsersData.columnTitles().map(column => {
              return <TableCell align="center" key={column}>{column}</TableCell>
            })}
            <TableCell>계약여부</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data ? data.map(row => {
              return (
                <TableRow
                  key={row.id}
                  onClick={() => history.push(`/user/${row.id}`)}
                  hover
                >
                  <TableCell align="center">{(((page - 1) * PAGE_SIZE) + data.indexOf(row)) + 1}</TableCell>
                  <TableCell align="center">{formatDate(row.joinDate)}</TableCell>
                  <TableCell align="center">{row.userType}</TableCell>
                  <TableCell align="center">{emailSplit(row.email)}</TableCell>
                  <TableCell align="center">{row.country === 'Unknown(null)' ? '-' : row.country}</TableCell>
                  <TableCell align="center">{row.phone}</TableCell>
                  <TableCell align="center">{formatDate(row.latestContractDate)}</TableCell>
                  <TableCell align="center">{row.cumulativeContractCount}</TableCell>
                  <TableCell align="center">{row.cumulativeStatementAmount}</TableCell>
                  <TableCell align="center">{row.userType === UserTypeEnum.CONTRACT ? 'Y' : 'N'}</TableCell>
                </TableRow>
              )
            }) : null
          }
          <TableRow>
            <TableCell colSpan={9} align="center" style={{ border: 0 }}>
              {
                data?.length
                  ? <Pagination
                    count={pageCount}
                    onChange={(e, page) => setPage(page)}
                    style={{ width: '100%' }}
                    page={page}
                  />
                  : "No records to display"
              }
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default UsersTable;