//"^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9]+\.[a-zA-Z]+" 앱에서 정규식을 통해서 string 이 들어옵니다. 
export const emailSplit = (email: string) => {
    const [id, domain] = email.split('@');
    return maskEmail(id, domain);
}
//TODO email 예외 처리에 대한 코드짜기 -> 4월 7일 목요일까지

export const maskEmail = (id: string, domain: string) => {
    if (id.length > 3) return `${id.slice(0, id.length - 3)}***@${domain}`;
    else return `${id[0]}***@${domain}`;
}

export const formatDate = (date: number) => {
    if (!date) return '-';

    const dateObj = new Date(date);

    return dateObj.toLocaleDateString('ko-KR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
};